import React, { useEffect, useState } from "react";
import axios from "axios";
import "./PerformanceChart.css";
import { useTheme } from "../../../../../CoinnectionThemes";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from "recharts";

const PerformanceChart = () => {
  const [chartData, setChartData] = useState(null);
  const { darkMode, toggleDarkMode } = useTheme();

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = await axios.get(
          `https://coinnection-server.onrender.com/api/all-performance`
        );

        if (response.data && response.data.length > 0) {
          const calculatePerformance = (start, end) => {
            return ((end - start) / start) * 100; // Performance in percentage
          };

          const filteredData = response.data.map((dataPoint) => {
            return {
              ...dataPoint,
              performance: calculatePerformance(
                dataPoint.fund_start,
                dataPoint.fund_end
              ),
              date: dataPoint.date,
            };
          });

          setChartData(filteredData);
        } else {
          console.log("No performance data found.");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchChartData();
  }, []);

  const formatYAxisTick = (value) => `${value.toFixed(2)}%`; // Display Y-axis values as percentages

  // Function to format X-axis ticks
  const formatXAxisTick = (value) => {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const day = date.getDate();
    return `${year}, ${month} ${day}th`;
  };

  // Modify CustomTooltip component accordingly
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date = new Date(label);
      const formattedDate = `${date.getFullYear()}, ${new Intl.DateTimeFormat(
        "en-US",
        { month: "long" }
      ).format(date)} ${date.getDate()}th`;
      return (
        <div
          className="custom-tooltip"
          style={{
            background: "#202937",
            padding: "5px",
            border: "1px solid #434651",
          }}
        >
          <div className="custom-tooltip" /* existing styling */>
            <p>{formattedDate}</p>
            <p>Performance: {payload[0].value.toFixed(2)}%</p>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`performance_chart_cont ${
        darkMode ? "dark-mode" : "white-mode"
      }`}
    >
      {chartData ? (
        <ResponsiveContainer width="100%" height={220}>
          <LineChart
            width={500}
            height={300}
            data={chartData}
            margin={{
              top: 5,
              right: 50,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#434651" />
            <XAxis
              dataKey="date"
              tickFormatter={formatXAxisTick}
              stroke="#434651"
            />
            <YAxis
              domain={[0, 50]}
              tickFormatter={formatYAxisTick}
              stroke="#434651"
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend formatter={(value) => `Performance`} />{" "}
            {/* Updated the Legend formatter */}
            <Line
              type="monotone"
              dataKey="performance" // This key should match the calculated performance
              stroke="#228b8b"
              dot={(data) =>
                data.date &&
                data.date.getDate &&
                (data.date.getDate() === 15 || data.date.getDate() === 30)
                  ? { r: 8 }
                  : false
              }
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <div>Loading chart...</div>
      )}
    </div>
  );
};

export default PerformanceChart;
