import React from "react";
import logo from "../../images/logo2.png";
import { Link } from "react-router-dom";

function LogoFooter() {
  return (
    <div className="logo_footer">
      <img src={logo} alt="Coinnection Logo" />
      <Link to="/">The Coinnection Fund</Link>
    </div>
  );
}

export default LogoFooter;
