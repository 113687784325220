import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";

const Profit_Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [hideRead, setHideRead] = useState(false); // State to toggle hiding read messages

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const token = Cookies.get("authToken");
        const decoded = jwt_decode(token);
        const userId = decoded.userId;

        const response = await axios.get(
          `http://localhost:9000/api/profit-notifications/${userId}`
        );
        setNotifications(response.data);
      } catch (error) {
        console.error("Error fetching profit notifications", error);
      }
    };

    fetchNotifications();
  }, []);

  const handleNotificationClick = async (notification) => {
    setSelectedNotification(notification);
    setShowPopup(true);

    if (!notification.read_status) {
      try {
        // Mark notification as read
        // Example endpoint, replace with your actual API endpoint
        const response = await axios.patch(
          `http://localhost:9000/api/user-notifications/${notification.notification_id}/read`
        );
        if (response.status === 200) {
          setNotifications(
            notifications.map((n) =>
              n.notification_id === notification.notification_id
                ? { ...n, read_status: true }
                : n
            )
          );
        }
      } catch (error) {
        console.error("Error updating notification status", error);
      }
    }
  };

  const Popup = () => (
    <div className="popup_notification">
      <div className="popup_inner">
        <h1>{selectedNotification?.notification_title}</h1>
        <p>{selectedNotification?.notification_content}</p>
        <button onClick={() => setShowPopup(false)}>Close</button>
      </div>
    </div>
  );

  const markAllAsRead = async () => {
    // This function will mark all notifications as read
    try {
      // Make an API call to mark all notifications as read
      // If you don't have such an API, you'll need to create it
      const response = await axios.patch(
        `http://localhost:9000/api/user-notifications/mark-all-read`
      );
      if (response.status === 200) {
        // Update all notifications as read in the state
        setNotifications(
          notifications.map((n) => ({ ...n, read_status: true }))
        );
      }
    } catch (error) {
      console.error("Error marking all notifications as read", error);
    }
  };

  const toggleHideRead = () => {
    setHideRead(!hideRead);
  };

  return (
    <div className="all_notifications_cont">
      <div className="notifications_header">
        <p>Profit Notifications</p>
        <div className="notifications_header_button_cont">
          <button onClick={markAllAsRead}>Read All</button>
          <label>
            <input
              type="checkbox"
              checked={hideRead}
              onChange={toggleHideRead}
            />{" "}
            Hide Read Messages
          </label>
        </div>
      </div>
      <div className="notifications_body">
        {notifications
          .filter((notification) => !hideRead || !notification.read_status)
          .map((notification, index) => (
            <div
              key={index}
              className={`notification_item ${
                notification.read_status ? "notification_read" : ""
              }`}
              onClick={() => handleNotificationClick(notification)}
            >
              <div className="notification_item_title_cont">
                <p>
                  <span>
                    <FontAwesomeIcon
                      className="notification_item_title_icon"
                      icon={
                        notification.read_status ? faEnvelopeOpen : faEnvelope
                      }
                    />
                  </span>
                  {notification.notification_title}
                </p>
              </div>
              <div className="notification_item_message_cont">
                <p>{notification.notification_content}</p>
              </div>
              <div className="notification_item_end_message_cont">
                <p className="notification_end_message">
                  {notification.notification_end_message}
                </p>
              </div>
            </div>
          ))}
      </div>
      {showPopup ? <Popup /> : null}
    </div>
  );
};

export default Profit_Notifications;
