import React from "react";
import "./Onboarding.css"; // Import your CSS file for styling
import WalletBackup from "../JoinFund_components_files/WalletBackup.pdf";
import { saveAs } from "file-saver";
import TopMenuFund from "../TopMenuFund";
import { useTranslation } from "react-i18next";
import ENonboarding from "../JoinFund_components_files/ENonboarding.pdf";
import DEonboarding from "../JoinFund_components_files/DEonboarding.pdf";

const Onboarding = () => {
  const { t, i18n } = useTranslation("Onboarding");
  const currentLanguage = i18n.language;

  const handleDownloadOnboarding = () => {
    let onboardingFile;

    if (currentLanguage === "de") {
      onboardingFile = DEonboarding;
    } else {
      onboardingFile = ENonboarding;
    }

    saveAs(onboardingFile, "Coinnection Fund Onboarding.pdf");
  };
  const handleDownloadBackup = () => {
    saveAs(WalletBackup, "Wallet Backup.pdf");
  };
  return (
    <div className="onboarding_container">
      <TopMenuFund />
      <div className="onboarding_step_tlt">
        <p>Coinnection Fund Onboarding Guide</p>
        <button onClick={handleDownloadOnboarding}>
          {t("onboarding_step_tlt_button_text")}
        </button>
      </div>

      <section className="onboarding_step">
        <div className="onboarding_step_left">
          <p className="onboarding_step_left_text">1</p>
        </div>
        <div className="onboarding_step_right">
          <p className="onboarding_box_title">{t("box_1_text_tlt")}</p>
          <div className="steps_cont">
            <div className="steps_cont_text">
              <ul>
                <li>{t("box_1_text_1")}</li>
              </ul>
              <button>
                <a
                  href="https://partner.bybit.com/b/coinnection"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bybit
                </a>
              </button>
            </div>
            <hr class="line_onboarding"></hr>
            <div className="steps_cont_text">
              <ul>
                <li>{t("box_1_text_2")}</li>
              </ul>
              <button onClick={handleDownloadBackup}>
                Download Wallet Backup
              </button>
            </div>
            <hr class="line_onboarding"></hr>
            <div className="steps_cont_text">
              <div className="steps_cont_text_1">
                <ul>
                  <li>{t("box_1_text_3")}</li>
                </ul>

                <span className="span_steps_cont_text_1">
                  {t("box_1_text_4")}
                </span>
                <span className="span_steps_cont_text_2">
                  {t("box_1_text_5")}
                </span>
              </div>
              <div className="steps_cont_buttons_cont">
                <div className="steps_cont_buttons_tlt">
                  <p>Download Metamask:</p>
                </div>
                <div className="steps_cont_buttons">
                  <button>
                    {" "}
                    <a
                      href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Browser Extension
                    </a>
                  </button>
                  <button>
                    {" "}
                    <a
                      href="https://metamask.app.link/skAH3BaF99"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      IOS app
                    </a>{" "}
                  </button>
                  <button>
                    {" "}
                    <a
                      href="https://metamask.app.link/bxwkE8oF99"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Android app
                    </a>
                  </button>
                </div>
              </div>
            </div>
            {/* Add more steps as needed */}
          </div>
        </div>
      </section>

      {/* Repeat the structure for other sections */}
      <section className="onboarding_step_2">
        <div className="onboarding_step_left">
          <p className="onboarding_step_left_text">2</p>
        </div>
        <div className="onboarding_step_right">
          <p className="onboarding_box_title">{t("box_1_text_tlt_2")}</p>
          <div className="steps_cont">
            <div className="steps_cont_text">
              <ul>
                <li>{t("box_1_text_6")}</li>
                <p className="box_1_text_6_txt">{t("box_1_text_6.1")}</p>
              </ul>
              <button>
                <a
                  href="https://coinnection.net/registration/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Register here
                </a>
              </button>
            </div>
            <hr class="line_onboarding"></hr>
            <div className="steps_cont_text">
              <ul>
                <li>{t("box_1_text_7")}</li>
              </ul>
            </div>
            <hr class="line_onboarding"></hr>
            <div className="steps_cont_text">
              <ul>
                <li>{t("box_1_text_8")}</li>
              </ul>
            </div>

            {/* Add more steps as needed */}
          </div>
        </div>
      </section>

      <section className="onboarding_step_3">
        <div className="onboarding_step_left">
          <p className="onboarding_step_left_text">3</p>
        </div>
        <div className="onboarding_step_right">
          <p className="onboarding_box_title">{t("box_1_text_tlt_3")}</p>
          <div className="steps_cont">
            <div className="steps_cont_text">
              <ul>
                <li>{t("box_1_text_9")}</li>
              </ul>
              <div className="steps_cont_buttons">
                <button>
                  <a
                    href="https://crypto.com/app/s2znp7zgfr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("box_1_text_9.1")}
                  </a>
                </button>
              </div>
            </div>
            <hr class="line_onboarding"></hr>
            <div className="steps_cont_text">
              <ul>
                <li>{t("box_1_text_10")}</li>
              </ul>
              <div className="steps_cont_buttons">
                <button>
                  <a
                    href="https://www.bitrefill.com/signup?code=5qawzeag"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("box_1_text_9.2")}
                  </a>
                </button>
              </div>
            </div>
            <hr class="line_onboarding"></hr>
            <div className="steps_cont_text">
              <ul>
                <li>{t("box_1_text_11")}</li>
              </ul>
              <div className="steps_cont_buttons">
                <button>
                  <a
                    href="https://affil.trezor.io/aff_c?offer_id=135&aff_id=33554"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("box_1_text_12")}
                  </a>
                </button>

                <button>
                  <a
                    href="https://shop.ledger.com/?r=9e56fb2122fe"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("box_1_text_13")}
                  </a>
                </button>
              </div>
            </div>

            {/* Add more steps as needed */}
          </div>
        </div>
      </section>

      {/* Repeat the structure for the last section */}
    </div>
  );
};

export default Onboarding;
