import React, { useState } from "react";
import "../../Webpage/Webpage_components/JoinFund_components_css/Faq.css";
import { useTranslation } from "react-i18next";
import TopMenuAffiliate from "./TopMenuAffiliate";

const FaqAffiliate = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { t } = useTranslation("FaqAffiliate");

  const handleQuestionClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const faqData = [
    {
      question: t("questions.0.question"),
      answer: t("questions.0.answer"),
    },
    {
      question: t("questions.1.question"),
      answer: t("questions.1.answer"),
    },
    {
      question: t("questions.2.question"),
      answer: t("questions.2.answer"),
    },
    {
      question: t("questions.3.question"),
      answer: t("questions.3.answer"),
    },
  ];

  return (
    <div className="faq_page">
      <TopMenuAffiliate />
      <div className="faq_tlt">
        <p>{t("title")}</p>
      </div>
      <div className="faq_utlt">
        <p>{t("subtitle")}</p>
      </div>

      <div className="faq_cont">
        <div className="faq_list">
          {faqData.map((faq, index) => (
            <div
              key={index}
              className={`faq_item ${activeIndex === index ? "active" : ""}`}
            >
              <div
                className="faq_question"
                onClick={() => handleQuestionClick(index)}
              >
                {faq.question}
              </div>
              {activeIndex === index && (
                <div
                  className="faq_answer"
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                ></div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqAffiliate;
