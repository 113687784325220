import React, { useState } from "react";
import axios from "axios";
import "./MonthEndUpdate.css";

const MonthEndUpdate = () => {
  const [monthEndValue, setMonthEndValue] = useState("");

  const updateMonthEndValue = () => {
    if (!monthEndValue) {
      alert("Please enter a valid Month End value");
      return;
    }

    axios
      .post(
        "https://coinnection-server-node.onrender.com/api/total-connection-fund/month-end",
        { value: monthEndValue } // Include the value property in the request body
      )
      .then(() => {
        alert("Total Connection Fund Month End value updated successfully");
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to update Total Connection Fund Month End value");
      });
  };

  return (
    <div className="month_end_update_cont">
      <div className="month_end_update_box">
        <form>
          <div className="month_end_update_box_header">
            <p>Update Month End Net Asset Value:</p>
          </div>

          <div className="month_end_update_box_body">
            <input
              type="number"
              id="monthEndValue"
              name="value" // Update the name attribute to "value"
              value={monthEndValue}
              placeholder="Type Month End NAV"
              onChange={(e) => setMonthEndValue(e.target.value)}
              required
            />
          </div>
          <div className="month_end_update_box_footer">
            <button
              type="button"
              onClick={updateMonthEndValue}
              className="wide_button"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MonthEndUpdate;
