import React, { useEffect, useState } from "react";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./Referrals.css";
import { useTheme } from "../../../CoinnectionThemes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faQrcode,
  faFileLines,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import QRCodeModal from "./QRCodeModal";

const Refferals = () => {
  const [referralCode, setReferralCode] = useState("");
  const [copied, setCopied] = useState(false);
  const [referrals, setReferrals] = useState([]);
  const [showQRCode, setShowQRCode] = useState(false);
  const [referralLink, setReferralLink] = useState("");
  const { darkMode, toggleDarkMode } = useTheme();
  const [referralIncome, setReferralIncome] = useState([]);
  const [codeGenerated, setCodeGenerated] = useState(false); // State to track whether a code has been generated

  const toggleQRCodePopUp = () => {
    if (referralCode) {
      const link = `https://coinnection.net/registration?referralCode=${referralCode}`;
      setReferralLink(link);
      setShowQRCode(!showQRCode);
    }
  };

  // Define a function to close the QR code modal
  const closeQRCodeModal = () => {
    setShowQRCode(false);
  };

  const authToken = Cookies.get("authToken");
  useEffect(() => {
    axios
      .get("https://coinnection-server.onrender.com/api/fetch-referral-code", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        const userReferralCode = response.data.referralCode;
        setReferralCode(userReferralCode);
        // Check if a code has been generated
        setCodeGenerated(!!userReferralCode);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const generateReferralCode = () => {
    axios
      .post(
        "https://coinnection-server.onrender.com/api/generate-referral-code",
        null,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        const userReferralCode = response.data.referralCode;
        setReferralCode(userReferralCode);
        setCodeGenerated(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const fetchReferrals = async () => {
      try {
        const response = await axios.get(
          // 'https://coinnection-server.onrender.com/api/fetch-referrals',
          "http://localhost:9000/api/fetch-referrals",
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
            withCredentials: true,
          }
        );
        const userReferrals = response.data.referrals;
        const referralEarnings = response.data.referralEarnings;
        console.log("Here is the referrals");
        console.log(userReferrals);
        setReferrals(userReferrals);
        setReferralIncome(referralEarnings);
      } catch (error) {
        console.error("Error fetching referrals:", error);
      }
    };

    const sendReferrals = async () => {
      try {
        // Assuming you have a 'referrals' array containing user referrals
        const response = await axios.post(
          "https://coinnection-server.onrender.com/api/add-referrals",
          { referrals }, // Send the user's referrals as an array
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
            withCredentials: true,
          }
        );
        console.log(response.data.message); // Log the success message
      } catch (error) {
        console.error("Error adding referrals:", error);
      }
    };

    fetchReferrals();
    sendReferrals(); // Call the function to send referrals
  }, [authToken]);

  // Create a function to modify the email string
  const hideEmail = (email) => {
    if (email) {
      const parts = email.split("@"); // Split the email into two parts: local and domain
      const localPart = parts[0];
      const domainPart = parts[1];

      // Hide a portion of the local part, leaving only the first and last characters visible
      const hiddenLocalPart =
        localPart.length > 2
          ? localPart[0] +
            "*".repeat(localPart.length - 2) +
            localPart[localPart.length - 1]
          : localPart; // Ensure at least one character remains visible

      return hiddenLocalPart + "@" + domainPart; // Recreate the email with hidden local part
    }
    return email; // Return the original email if it's empty
  };

  return (
    <div className={`referrals_cont ${darkMode ? "dark-mode" : "white-mode"}`}>
      <div className="referrals_cont_section_div">
        <div className="referrals_cont_section_cont_1">
          {/*  */}
          <div className="referrals_cont_box_1">
            <div className="referrals_cont_section_1_header">
              <p> Invite your friends, start earning together.</p>
            </div>

            <div className="referrals_cont_section_1_body">
              <p>
                The Coinnection Fund shares 5% Profit Split of your referrals
                monthly earnings.
              </p>

              <p className="referrals_cont_section_1_body_text_2">
                Get paid with friends every month and grow together with the
                Coinnection Fund!
              </p>

              <p>
                <FontAwesomeIcon
                  icon={faFileLines}
                  className="terms_icon_referral"
                />
                <a href="https://coinnection.net/terms">
                  View Terms and Conditions
                </a>
              </p>
            </div>
          </div>
          <div className="referrals_cont_box_2">
            <div className="referrals_cont_box_2_cont">
              <div className="referrals_cont_box_2_header">
                <p>Referral Tools </p>
              </div>
              <div className="referrals_cont_box_2_body">
                <div className="referral_commission_cont">
                  <p className="referral_commission_cont_code_title">
                    Commission
                  </p>
                  <p className="referral_commission_cont_code"> 5%</p>
                </div>
                <div className="referral_code_cont">
                  <p className="referral_code_cont_code_title">Referral Code</p>
                  <div className="referral_code_cont_input">
                    <p className="referral_code_cont_code"> {referralCode} </p>

                    <p>
                      <CopyToClipboard text={referralCode} onCopy={handleCopy}>
                        <FontAwesomeIcon
                          icon={faCopy}
                          onClick={handleCopy}
                          className="copy_icon_referral"
                        />
                      </CopyToClipboard>
                      {copied && (
                        <span className="copied_message_referral">Copied!</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="referral_code_link_cont">
                  <p className="referral_code_cont_code_title">
                    Referral Link{" "}
                  </p>
                  <div className="referral_code_cont_input">
                    <p className="referral_code_cont_link">
                      https://coinnection.net...referralCode=
                      {referralCode}
                    </p>

                    <p>
                      <CopyToClipboard
                        text={`https://coinnection.net/registration?referralCode=${referralCode}`}
                        onCopy={handleCopy}
                      >
                        <FontAwesomeIcon
                          icon={faCopy}
                          onClick={handleCopy}
                          className="copy_icon_referral"
                        />
                      </CopyToClipboard>
                      {copied && (
                        <span className="copied_message_referral">Copied!</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="referral_buttons_cont">
                  <button className="mini_button" onClick={toggleQRCodePopUp}>
                    <FontAwesomeIcon
                      icon={faQrcode}
                      className="qr_icon_referral"
                    />
                  </button>
                </div>

                {!codeGenerated && (
                  <div className="generateReferralCode_button">
                    <button onClick={generateReferralCode}>
                      Generate Referral Code
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="referrals_cont_section_cont_2">
          <div className="referrals_cont_box_id345xy">
            <div className="referrals_cont_box_id345xy_cont">
              <div className="referrals_cont_box_id345xy_header">
                <div className="referrals_cont_box_id345xy_header_box">
                  <p>Overview </p>
                </div>
                <div className="referrals_cont_box_id345xy_header_box_2">
                  <button className="select_button">All </button>

                  <button className="select_button">1 Month </button>
                  <button className="select_button">6 months </button>
                  <button className="select_button">12 Months </button>
                </div>
              </div>
              <div className="referrals_cont_box_id345xy_body">
                <div className="referrals_cont_box_id345xy_body_top">
                  <div className="referrals_cont_box_id345xy_body_top_box">
                    <p className="referrals_cont_box_id345xy_body_top_box_title">
                      Your Earnings
                    </p>
                    <p className="referrals_cont_box_id345xy_body_top_box_value">
                      0₮ USDT
                    </p>
                  </div>
                  <div className="referrals_cont_box_id345xy_body_top_box">
                    <p className="referrals_cont_box_id345xy_body_top_box_title">
                      Friends
                    </p>
                    <p className="referrals_cont_box_id345xy_body_top_box_value">
                      {referrals.length} {/* Display the number of referrals */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="referrals_cont_box_3">
            <div className="referrals_cont_box_3_cont">
              <div className="referrals_cont_box_3_header">
                <p>My Referrals</p>
              </div>
              <div className="referrals_cont_box_3_body">
                <table>
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>Investment Amount</th>
                      <th>Registration Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referrals.map((referral) => (
                      <tr key={referral.id}>
                        <td>{hideEmail(referral.email)}</td>
                        <td>{referral.investment_amount}₮ USDT</td>
                        <td>in progess</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="referrals_cont_section_cont_3">
          <div className="referrals_cont_box_5">
            <div className="referrals_cont_box_5_cont">
              <div className="referrals_cont_box_5_header">
                <p>My Earnings</p>
              </div>
              <div className="referrals_cont_box_5_body">
                <table>
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>Referral earnings</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referralIncome.map((referral) => (
                      <tr key={referral.id}>
                        <td>{hideEmail(referral.email)}</td>
                        <td>
                          {referral.referralEarnings === 0
                            ? ""
                            : `${referral.referralEarnings}₮ USDT`}
                        </td>
                        <td>{referral.date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="referrals_cont_box_id345xy">
            <div className="referrals_cont_box_id345xy_cont">
              <div className="referrals_cont_box_id345xy_header">
                <div className="referrals_cont_box_id345xy_header_box">
                  <p>Pay-outs</p>
                </div>
              </div>
              <div className="referrals_cont_box_id345xy_body">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Pay-out amount</th>
                      <th>Transaction ID</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referrals.map((referral) => (
                      <tr key={referral.id}></tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Show the QR code pop-up as a modal if 'showQRCode' is true */}
      {showQRCode && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeQRCodeModal}>
              &times;
            </span>
            <QRCodeModal
              referralLink={referralLink}
              onClose={closeQRCodeModal}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Refferals;
