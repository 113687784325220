import React from "react";
import LogoRegisteri from "../../../images/logo2.png";
import { Link } from "react-router-dom";

function LogoModal() {
  return (
    <div className="logo_register_form">
      <img src={LogoRegisteri} alt="Coinnection Logo" />
      <Link to="/">The Coinnection Fund</Link>
    </div>
  );
}

export default LogoModal;
