import React, { useState, useEffect } from "react";
import axios from "axios";
import "./components/AffiliateDashboard.css";
import "./components/affiliate_dashboard_components_css/TopMenuAffiliateDashboard.css";
import { useTranslation } from "react-i18next";
import AffiliateOverview from "./components/AffiliateOverview";
import AffiliateSettings from "./components/AffiliateSettings";
import Cookies from "js-cookie";
import en from "../images/us.png";
import de from "../images/de.png";
import es from "../images/es.png";
import AffiliateLogin from "./components/AffiliateLogin";
import { useTheme } from "../CoinnectionThemes";
import { BsGlobe } from "react-icons/bs";
import { Oval } from "react-loading-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDesktop,
  faFileInvoiceDollar,
  faUser,
  faGear,
  faChartBar,
  faRightFromBracket,
  faHourglassEnd,
  faLightbulb,
  faMoon,
  faBell,
} from "@fortawesome/free-solid-svg-icons";

const AffiliateDashboard = () => {
  const { t, i18n } = useTranslation("Affiliate_Dashboard");
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    Cookies.get("activeTab") || "AffiliateDashboard"
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoggedInAffiliate, setIsLoggedInAffiliate] = useState(false);
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState(null);
  const { darkMode, toggleDarkMode } = useTheme();
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownAlertOpen, setDropdownAlertOpen] = useState(false);
  const [approvedAffiliate, setApprovedAffiliate] = useState(false);

  // Function to check login status
  const checkLoginStatusAffiliate = async () => {
    try {
      const token = Cookies.get("authTokenAffiliate");

      if (!token) {
        setIsLoggedInAffiliate(false);
        return;
      }

      const response = await axios.get(
        "http://localhost:9000/api/check-login-affiliate",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { isLoggedInAffiliate, approvedAffiliate, userId, username } =
        response.data; // Extract userId and username from the response
      setIsLoggedInAffiliate(isLoggedInAffiliate);
      setUsername(username);
      setApprovedAffiliate(approvedAffiliate);
      setUserId(userId);
    } catch (error) {
      console.log("Error checking login status:", error);
      setIsLoggedInAffiliate(false);
      setUsername("");
      setApprovedAffiliate(false);
    }
  };

  useEffect(() => {
    checkLoginStatusAffiliate();
  }, []);

  const handleLoginCallback = (
    isLoggedInAffiliate,
    approvedAffiliate,
    userId
  ) => {
    setIsLoggedInAffiliate(isLoggedInAffiliate);
    setApprovedAffiliate(approvedAffiliate);
    setUserId(userId);
  };

  // Function to handle logout
  const handleLogoutAffiliate = async () => {
    try {
      // Simply make the logout API request without credentials
      await axios.get("http://localhost:9000/api/logout");
      setIsLoggedInAffiliate(false);
      setUsername("");
      setApprovedAffiliate(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Function to check login status
  // useEffect to check login status when the component mounts
  useEffect(() => {
    document.title = "Dashboard Coinnection";
  }, []);

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // get all menu items with submenus
  const menuItems = document.querySelectorAll(
    ".affiliate_menu_container li.has-submenu"
  );

  // add click event listener to each menu item
  menuItems.forEach((item) => {
    item.addEventListener("click", () => {
      // toggle the show-submenu class on the clicked item
      item.classList.toggle("show-submenu");
    });
  });

  const handleLanguageClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setIsDropdownOpen(false);
    i18n.changeLanguage(language);
  };

  const toggleAlertDropdown = () => {
    setDropdownAlertOpen((prevOpen) => !prevOpen);
  };

  const handleToggleDarkMode = () => {
    toggleDarkMode();
    localStorage.setItem("preferredTheme", darkMode ? "dark" : "white");
  };

  useEffect(() => {
    const handleAlertClickOutside = (event) => {
      const dropdownContent = document.querySelector(
        ".affiliate_profile_dropdown_content"
      );

      if (dropdownContent && !dropdownContent.contains(event.target)) {
        setDropdownAlertOpen(false);
      }
    };

    document.addEventListener("mousedown", handleAlertClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleAlertClickOutside);
    };
  }, []);

  useEffect(() => {
    const dropdownButton = document.querySelector(".profile_button");
    const dropdownContent = document.querySelector(
      ".affiliate_profile_dropdown"
    );

    const handleClickOutside = (event) => {
      if (dropdownContent && !dropdownContent.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleAlertClickOutside = (event) => {
      const dropdownContent = document.querySelector(
        ".affiliate_profile_dropdown_content"
      );

      if (dropdownContent && !dropdownContent.contains(event.target)) {
        setDropdownAlertOpen(false);
      }
    };

    document.addEventListener("mousedown", handleAlertClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleAlertClickOutside);
    };
  }, []);

  return (
    <div className="affiliate_dashboard_container">
      <div className="affiliate_dashboard_container_left">
        <div className="side_menu_container">
          <div className="logo_container">
            <img
              src={require("../images/logo1.png")}
              alt="logo"
              className="logo_1"
            />
            <p className="affiliate_dashtittle">Coinnection Affiliates</p>
          </div>
          <hr class="line"></hr>

          <ul>
            <li
              className={
                selectedMenuItem === "AffiliateDashboard" ? "active" : ""
              }
              onClick={() => handleMenuItemClick("AffiliateDashboard")}
            >
              <FontAwesomeIcon icon={faDesktop} />{" "}
              <span className="affiliate_menu_text">Dashboard</span>
            </li>

            <li
              className={
                selectedMenuItem === "AffiliateSettings" ? "active" : ""
              }
              onClick={() => handleMenuItemClick("AffiliateSettings")}
            >
              <FontAwesomeIcon icon={faGear} />{" "}
              <span className="affiliate_menu_text">Settings</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="affiliate_dashboard_container_right">
        <div className="affiliate_dashboard_container_header">
          <div className="top_menu_dashboard">
            <div className="profile_container">
              <div className="profile_dropdown">
                <button
                  className="theme-toggle-button"
                  onClick={handleToggleDarkMode}
                >
                  <FontAwesomeIcon
                    icon={darkMode ? faLightbulb : faMoon}
                    className="top_menu_icon"
                  />
                </button>
                <button
                  className="profile_button_alert"
                  onClick={toggleAlertDropdown}
                >
                  <FontAwesomeIcon icon={faBell} className="top_menu_icon" />
                </button>

                {dropdownAlertOpen && (
                  <ul
                    className={`alert_dropdown_content ${
                      dropdownAlertOpen ? "open" : ""
                    }`}
                  >
                    <li>
                      <FontAwesomeIcon icon={faBell} />
                      <span className="dropdown_menu_text_alert">
                        notification!
                      </span>
                    </li>
                  </ul>
                )}

                <button className="profile_button" onClick={toggleDropdown}>
                  <FontAwesomeIcon icon={faUser} className="top_menu_icon" />
                </button>

                {dropdownOpen && (
                  <ul
                    className={`profile_dropdown_content ${
                      dropdownOpen ? "open" : ""
                    }`}
                  >
                    <li onClick={() => handleMenuItemClick("Settings")}>
                      <FontAwesomeIcon icon={faGear} />
                      <span className="dropdown_menu_text">Settings</span>
                    </li>
                    <li onClick={() => handleMenuItemClick("Home")}>
                      <FontAwesomeIcon icon={faDesktop} />
                      <span className="dropdown_menu_text">Overview</span>
                    </li>
                    <li onClick={handleLogoutAffiliate}>
                      <FontAwesomeIcon icon={faRightFromBracket} />
                      <span className="dropdown_menu_text">Log-out</span>
                    </li>
                  </ul>
                )}

                <div
                  className="fund_menu_links_language"
                  onClick={handleLanguageClick}
                >
                  <div className="language-dropdown">
                    <div className="dropdown-trigger">
                      <span>
                        {" "}
                        <BsGlobe className="BsGlobe_topMenuFund" />
                      </span>
                    </div>
                    {isDropdownOpen && (
                      <div className="dropdown-menu show">
                        <div onClick={() => handleLanguageChange("en")}>
                          <img src={en} alt="English Flag" />
                          <span>{t("English")}</span>
                        </div>
                        <div onClick={() => handleLanguageChange("de")}>
                          <img src={de} alt="German Flag" />
                          <span>{t("Deutsch")}</span>
                        </div>
                        <div onClick={() => handleLanguageChange("es")}>
                          <img src={es} alt="Spanish Flag" />
                          <span>{t("Español")}</span>
                        </div>
                        {/* Add more language options here */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoggedInAffiliate ? (
          approvedAffiliate ? (
            <div className="affiliate_content_container">
              {selectedMenuItem === "AffiliateDashboard" && (
                <AffiliateOverview />
              )}
              {selectedMenuItem === "AffiliateSettings" && (
                <AffiliateSettings />
              )}
            </div>
          ) : (
            <div className="approval_container">
              <div className="approval_container_box">
                {/* ... Your approval pending content ... */}
              </div>
            </div>
          )
        ) : (
          <div
            className={`signin_cont ${
              isLoggedInAffiliate ? "hidden" : "dimmed-background"
            }`}
          >
            <AffiliateLogin onLogin={handleLoginCallback} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AffiliateDashboard;
