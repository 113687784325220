import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "./PerformanceAnalytics.css";
import { useTheme } from "../../../../../CoinnectionThemes";

// Helper function to format the date as "August 15th Monday (Semi-month 1)"
const formatDate = (dateStr) => {
  const options = { month: "long", day: "numeric", weekday: "long" };
  const date = new Date(dateStr);
  const dayOfMonth = date.getDate();
  const semiMonth =
    dayOfMonth <= 15 ? "1st 14-Day period" : "2nd 14-Day period";
  return `${date.toLocaleDateString("en-US", options)} (${semiMonth})`;
};

const PerformanceAnalytics = () => {
  const [userRows, setUserRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { darkMode, toggleDarkMode } = useTheme();

  useEffect(() => {
    const fetchPerformanceAnalytics = async () => {
      try {
        const authToken = Cookies.get("authToken"); // Retrieve the authToken from cookies
        const response = await axios.get(
          `https://coinnection-server.onrender.com/api/performance-analytics`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
            withCredentials: true,
          }
        );

        if (response.data && response.data.length > 0) {
          setUserRows(response.data);
          setIsLoading(false);
        } else {
          console.log("No performance analytics data found.");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchPerformanceAnalytics();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className={`performance_cont ${darkMode ? "dark-mode" : "white-mode"}`}
    >
      <div className="tbody-container">
        <table>
          <thead>
            <tr>
              <th>Start Net Asset Value</th>
              <th>End Net Asset Value</th>
              <th>14-day Performance</th>
              <th>Share of Fund</th>
              <th>Profit Split</th>
              <th>Profit Share</th>

              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {userRows.map((row) => (
              <tr key={row.id}>
                <td>
                  {parseFloat(row.fund_start).toFixed(2).toLocaleString()}$
                </td>
                <td>{parseFloat(row.fund_end).toFixed(2).toLocaleString()}$</td>
                <td>
                  {parseFloat(row.monthly_performance)
                    .toFixed(2)
                    .toLocaleString()}
                  $
                </td>
                <td>
                  {parseFloat(row.share_of_fund).toFixed(2).toLocaleString()}$
                </td>
                <td>{row.profit_split}%</td>
                <td>
                  {parseFloat(row.profit_share).toFixed(2).toLocaleString()}$
                </td>
                <td>{formatDate(row.created_at)}</td>{" "}
                {/* Use the formatted date */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PerformanceAnalytics;
