import React, { useState, useEffect } from "react";
import moment from "moment";
import "./AffiliateOverview.css";
import axios from "axios";

const AffiliateOverview = () => {
  const [monthStart, setMonthStart] = useState(0);
  const [monthEnd, setMonthEnd] = useState(0);
  const [isLoadingStart, setIsLoadingStart] = useState(true);
  const [isLoadingEnd, setIsLoadingEnd] = useState(true);
  const [timerData, setTimerData] = useState({
    day: "",
    hours: "",
    minutes: "",
    seconds: "",
  });

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://coinnection-server-node.onrender.com/api/total-connection-fund/month-start"
        );

        setMonthStart(response.data.monthStart);
        setIsLoadingStart(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    axios
      .get(
        "https://coinnection-server-node.onrender.com/api/total-connection-fund/month-end"
      )
      .then((response) => {
        setMonthEnd(response.data.monthEnd);
        setIsLoadingEnd(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  function formatNumberWithSingleQuote(number) {
    return number.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  }

  function formatNumberWithSingleQuoteOther(number) {
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  }

  useEffect(() => {
    const updateTime = () => {
      setTimerData({
        day: moment().format("D"), // Use "D" to get the day of the month
        hours: moment().format("k"),
        minutes: moment().format("mm"),
        seconds: moment().format("ss"),
      });
      requestAnimationFrame(updateTime);
    };

    updateTime();
  }, []);

  useEffect(() => {
    const calculateCountdown = () => {
      const now = new Date();
      const endOfMonth = new Date(
        now.getFullYear(),
        now.getMonth() + 1,
        0,
        23,
        59,
        59,
        999
      );
      const remainingTime = endOfMonth.getTime() - now.getTime();

      const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    };

    const interval = setInterval(() => {
      calculateCountdown();
    }, 1000);

    calculateCountdown();

    return () => clearInterval(interval);
  }, []);

  const countdownString = `${countdown.days}d ${countdown.hours}h ${countdown.minutes}m ${countdown.seconds}s`;
  // Format the current year and month
  const currentYear = moment().format("YYYY");
  const currentMonth = moment().format("MMMM");

  return (
    <div className="affiliate_overview_page">
      <div className="affiliate_overview_cont_1">
        <div className="affiliate_overview_box">
          <div className="affiliate_overview_box_header">
            <div className="affiliate_overview_box_header_cont1">
              <p>14-Day Fund statistics</p>
            </div>
            <div className="affiliate_overview_box_header_cont1">
              <p>Date picker</p>
            </div>
          </div>
          <div className="affiliate_overview_box_body">
            <div className="affiliate_overview_box_body_column1">
              <div className="affiliate_overview_box_body_column1_box">
                <p className="affiliate_overview_box_body_column1_box_tlt">
                  Previous Start Net Asset Value
                </p>
                <p className="affiliate_overview_box_body_column1_box_utlt">
                  {formatNumberWithSingleQuote(parseFloat(monthStart))}
                  <span className="usdt_result_span">₮ USDT</span>
                </p>
              </div>
              <div className="affiliate_overview_box_body_column1_box">
                <p className="affiliate_overview_box_body_column1_box_tlt">
                  Previous End Net Asset Value
                </p>
                <p className="affiliate_overview_box_body_column1_box_utlt">
                  {formatNumberWithSingleQuoteOther(parseFloat(monthEnd))}
                  <span className="usdt_result_span">₮ USDT</span>
                </p>
              </div>
            </div>
            <div className="affiliate_overview_box_body_column2">
              <div className="affiliate_overview_box_body_column2_box">
                <p className="affiliate_overview_box_body_column1_box_tlt">
                  Previous 14-Day Performance
                </p>
                <p className="affiliate_overview_box_body_column1_box_utlt">
                  1000$
                </p>
              </div>
              <div className="affiliate_overview_box_body_column2_box">
                <p className="affiliate_overview_box_body_column1_box_tlt">
                  Next Update In:
                </p>
                <p className="affiliate_overview_box_body_column1_box_utlt">
                  {timerData.hours}:{timerData.minutes}:{timerData.seconds}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="affiliate_overview_box">
          <div className="affiliate_overview_box_header">
            <div className="affiliate_overview_box_header_cont1">
              <p>My Statistics</p>
            </div>
            <div className="affiliate_overview_box_header_cont1">
              <p>something here maybe</p>
            </div>
          </div>
          <div className="affiliate_overview_box_body">
            <div className="affiliate_overview_box_body_column1">
              <div className="affiliate_overview_box_body_column1_box">
                <p className="affiliate_overview_box_body_column1_box_tlt">
                  Previous month payout
                </p>
                <p className="affiliate_overview_box_body_column1_box_utlt">
                  543₮
                </p>
              </div>
              <div className="affiliate_overview_box_body_column1_box">
                <p className="affiliate_overview_box_body_column1_box_tlt">
                  Total pay-out this year
                </p>
                <p className="affiliate_overview_box_body_column1_box_utlt">
                  6055₮
                </p>
              </div>
            </div>
            <div className="affiliate_overview_box_body_column2">
              <div className="affiliate_overview_box_body_column2_box">
                <p className="affiliate_overview_box_body_column1_box_tlt">
                  Partner since
                </p>
                <p className="affiliate_overview_box_body_column1_box_utlt">
                  234 Days
                </p>
              </div>
              <div className="affiliate_overview_box_body_column2_box">
                <p className="affiliate_overview_box_body_column1_box_tlt">
                  No of Sign-ups
                </p>
                <p className="affiliate_overview_box_body_column1_box_utlt">
                  5
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="affiliate_overview_cont_2">
        <div className="affiliate_overview_cont_2_box">
          <div className="affiliate_overview_cont_2__header">
            <div className="affiliate_overview_cont_2__header_cont1">
              <p>User 1 E***@gmail.com</p>
            </div>
            <div className="affiliate_overview_cont_2__header_cont1">
              <p>something</p>
            </div>
          </div>
          <div className="affiliate_overview_cont_2_body">
            <div className="affiliate_overview_cont_2_body_column1">
              <div className="affiliate_overview_cont_2_body_column1_box">
                <p className="affiliate_overview_cont_2_body_column1_box_tlt">
                  Share of Fund
                </p>
                <p className="affiliate_overview_cont_2_body_column1_box_utlt">
                  1000$ (10%)
                </p>
              </div>
              <div className="affiliate_overview_cont_2_body_column1_box">
                <p className="affiliate_overview_cont_2_body_column1_box_tlt">
                  Previous months profit
                </p>
                <p className="affiliate_overview_cont_2_body_column1_box_utlt">
                  6055₮
                </p>
              </div>
            </div>
            <div className="affiliate_overview_cont_2_body_column2">
              <div className="affiliate_overview_cont_2_body_column2_box">
                <p className="affiliate_overview_cont_2_body_column1_box_tlt">
                  Investor since
                </p>
                <p className="affiliate_overview_cont_2_body_column1_box_utlt">
                  134 Days
                </p>
              </div>
              <div className="affiliate_overview_cont_2_body_column2_box">
                <p className="affiliate_overview_cont_2_body_column1_box_tlt">
                  Previous month affiliate profits
                </p>
                <p className="affiliate_overview_cont_2_body_column1_box_utlt">
                  110₮
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateOverview;
