import React, { useEffect, useState } from "react";
import axios from "axios";
import "./overview.css";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { useTheme } from "../../../CoinnectionThemes";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../../LoadingStateSpinner";
import LoadingStateSpinnerMonthlyPerformance from "../../../LoadingStateSpinnerMonthlyPerformance";
import LoadingStateSpinnerMonthEnd from "../../../LoadingStateSpinnerMonthEnd";
import LoadingStateSpinnerProfitShare from "../../../LoadingStateSpinnerProfitShare";
import PerformanceAnalytics from "./overview_components/analytics_components/PerformanceAnalytics";
import PerformanceChart from "./overview_components/analytics_components/PerformanceChart";
import ProfitsBarChart from "./overview_components/analytics_components/ProfitsBarChart";
import Clock from "./overview_components/Clock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faCalendarDay,
  faChartLine,
  faChartPie,
  faMoneyBills,
  faCircleInfo,
  faCirclePlus,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";

const Overview = ({ userId }) => {
  console.log("Overview userId:", userId);

  const { t } = useTranslation("Overview");
  const [monthStart, setMonthStart] = useState(0);
  const [monthEnd, setMonthEnd] = useState(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [lastEndNetAssetValue, setLastEndNetAssetValue] = useState(null);
  const [lastMonthlyPerformance, setLastMonthlyPerformance] = useState(null);
  const [lastProfitShare, setLastProfitShare] = useState(null);
  const [lastFundStart, setLastFundStart] = useState(null);
  const [shareOfFund, setShareOfFund] = useState(null); // or some initial value

  const [monthlyPerformance, setMonthlyPerformance] = useState(0);

  const [monthlyPerformancePercentage, setMonthlyPerformancePercentage] =
    useState(0);
  const [profitSplit, setProfitSplit] = useState(0);
  const [profitShare, setProfitShare] = useState(null);

  const [showPopup, setShowPopup] = useState(false);
  const [showPopupStart, setShowPopupStart] = useState(false);
  const [showPopupEnd, setShowPopupEnd] = useState(false);
  const [showPopupMonthlyPerformance, setShowPopupMonthlyPerformance] =
    useState(false);
  const [showPopupShareOfFund, setShowPopupShareOfFund] = useState(false);

  const [showPopupProfitSplit, setShowPopupProfitSplit] = useState(false);
  const { darkMode, toggleDarkMode } = useTheme();
  const [isLoadingStart, setIsLoadingStart] = useState(true);
  const [isLoadingEnd, setIsLoadingEnd] = useState(true);
  const [isLoadingMonthlyPerformance, setIsLoadingMonthlyPerformance] =
    useState(true);
  const [isLoadingProfitSplit, setIsLoadingProfitSplit] = useState(false);
  const [isLoadingProfitShare, setIsLoadingProfitShare] = useState(false);

  const [authToken, setAuthToken] = useState(Cookies.get("authToken")); // Initialize authToken state

  const popupTexts = [
    t("popupTexts_0"),
    t("popupTexts_1"),
    t("popupTexts_2"),
    t("popupTexts_3"),
    t("popupTexts_4"),
    t("popupTexts_2_1"),
  ];

  useEffect(() => {
    axios
      .get(
        "https://coinnection-server-node.onrender.com/api/total-connection-fund/monthly-performance"
      )
      .then((response) => {
        setMonthlyPerformance(response.data.monthlyPerformance);
        if (monthlyPerformance !== 0) {
          const percentage =
            (response.data.monthlyPerformance / monthStart) * 100;
          setMonthlyPerformancePercentage(percentage);
          setIsLoadingMonthlyPerformance(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [monthlyPerformance, monthStart]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://coinnection-server-node.onrender.com/api/total-connection-fund/month-start"
        );

        setMonthStart(response.data.monthStart);
        setIsLoadingStart(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    axios
      .get(
        "https://coinnection-server-node.onrender.com/api/total-connection-fund/month-end"
      )
      .then((response) => {
        setMonthEnd(response.data.monthEnd);
        setIsLoadingEnd(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (authToken && userId) {
      axios
        .get(`https://coinnection-server.onrender.com/api/user/${userId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the authToken in the headers
          },
          withCredentials: true,
        })
        .then((response) => {
          setLastEndNetAssetValue(response.data.last_end_net_asset_value);
          setLastMonthlyPerformance(response.data.last_monthly_performance);
          setLastProfitShare(response.data.last_profit_share);
          setLastFundStart(response.data.last_fund_start);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [authToken, userId]);

  const fetchUserData = async () => {
    if (authToken && userId) {
      // Ensure userId is not null
      try {
        const response = await axios.get(
          `https://coinnection-server.onrender.com/api/user/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`, // Include the authToken in the headers
            },
            withCredentials: true,
          }
        );

        console.log("API Response:", response.data);

        if (response.data.profit_share !== undefined) {
          console.log("Profit share:", response.data.profit_share);
          const parsedProfitShare = parseFloat(response.data.profit_share);

          if (!isNaN(parsedProfitShare)) {
            setProfitShare(parsedProfitShare);
          } else {
            console.log("Invalid profit share value");
          }
        } else {
          console.log("Profit share not found in the response");
        }

        setIsLoadingProfitSplit(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  console.log("Fetching user data...");
  fetchUserData();

  // Fetch investment amount directly
  useEffect(() => {
    axios
      .get(
        "https://coinnection-server.onrender.com/api/user/investment-amount",
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        const investmentAmount = response.data.investment_amount;
        setShareOfFund(investmentAmount);
        console.log("Investment amount:", investmentAmount);
      })
      .catch((error) => {
        console.error("Error fetching investment amount:", error);
        // Handle the error, e.g., set a state variable to show an error message to the user.
      });
  }, []);

  useEffect(() => {
    const fetchProfitSplit = async () => {
      try {
        const response = await axios.get(
          "https://coinnection-server.onrender.com/api/user/profit-split",
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
            withCredentials: true,
          }
        );

        const { profit_split } = response.data;

        const parsedProfitSplit = parseFloat(profit_split);

        if (!isNaN(parsedProfitSplit)) {
          setProfitSplit(parsedProfitSplit);
        } else {
          setIsLoadingProfitSplit(false);
        }
      } catch (error) {
        console.error(error);
        setIsLoadingProfitSplit(false);
      }
    };

    fetchProfitSplit();
  }, []);

  useEffect(() => {
    const fetchProfitShare = async () => {
      try {
        const response = await axios.get(
          "https://coinnection-server.onrender.com/api/user/profit-share",
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
            withCredentials: true,
          }
        );

        console.log("Response-profit_share:", response.data);

        if (response.data.profit_share !== null) {
          const parsedProfitShare = parseFloat(response.data.profit_share);

          if (!isNaN(parsedProfitShare)) {
            setProfitShare(parsedProfitShare);
          } else {
            setIsLoadingProfitShare(true);
          }
        } else {
        }
      } catch (error) {
        console.error(error);
        setIsLoadingProfitShare(false);
      }
    };

    fetchProfitShare();
  }, []);

  function formatNumberWithSingleQuote(number) {
    return number.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  }

  function formatNumberWithSingleQuoteOther(number) {
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  }

  return (
    <div className={`overview_cont ${darkMode ? "dark-mode" : "white-mode"}`}>
      <div className="overview">
        <div className="overview_top">
          <div className="analytics_box_clock_payout">
            <Clock />
          </div>
          {isLoadingStart ? (
            <LoadingSpinner />
          ) : (
            <div className="analytics_box">
              <div className="tltdash">
                <div
                  className="info-icon-container"
                  onClick={() => setShowPopupStart(!showPopupStart)}
                  onMouseEnter={() => setShowPopupStart(true)}
                  onMouseLeave={() => setShowPopupStart(false)}
                >
                  <p className="analytics_box_tlt">
                    {t("start_nav")}
                    <span className="faCircleInfo">
                      <FontAwesomeIcon icon={faCircleInfo} />
                    </span>
                  </p>
                </div>

                <li className="faCalendarDays">
                  <FontAwesomeIcon icon={faCalendarDays} />
                </li>
              </div>
              <hr class="line"></hr>

              <div className="result">
                <div className="analytics_box_month_end_txt_cont">
                  <p className="analytics_box_month_end_txt">
                    {t("last_start_nav")}
                  </p>
                  <p className="result_value_txt">
                    {lastFundStart}
                    <span className="usdt_result_span">₮ USDT</span>
                  </p>
                </div>
                <div className="process_txt_cont">
                  <p className="analytics_box_month_end_txt">
                    {t("current_start_nav")}
                  </p>
                  <p className="result_value_txt">
                    {formatNumberWithSingleQuote(parseFloat(monthStart))}
                    <span className="usdt_result_span">₮ USDT</span>
                  </p>
                </div>
              </div>
              {showPopupStart && (
                <div className="popup_overview_box">
                  <p>{popupTexts[0]}</p>
                </div>
              )}
            </div>
          )}

          <div className="analytics_box">
            <div className="tltdash">
              <div
                className="info-icon-container"
                onClick={() => setShowPopup(!showPopup)}
                onMouseEnter={() => setShowPopupEnd(true)}
                onMouseLeave={() => setShowPopupEnd(false)}
              >
                <p className="analytics_box_tlt">
                  {t("end_nav")}
                  <span className="faCircleInfo">
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </span>
                </p>
              </div>

              <li className="faCalendarDays">
                <FontAwesomeIcon icon={faCalendarDay} />
              </li>
            </div>
            <hr class="line"></hr>
            <div className="result">
              <div className="analytics_box_month_end_txt_cont">
                <p className="analytics_box_month_end_txt">
                  Last End Net Asset Value
                </p>

                <p className="result_value_txt">
                  {lastEndNetAssetValue}{" "}
                  <span className="usdt_result_span">₮ USDT</span>
                </p>
              </div>

              {isLoadingEnd ? (
                <LoadingStateSpinnerMonthEnd />
              ) : monthStart === monthEnd ? (
                <div>
                  <LoadingStateSpinnerMonthEnd />
                </div>
              ) : (
                <p className="result_value_txt">
                  {formatNumberWithSingleQuoteOther(parseFloat(monthEnd))}
                  <span className="usdt_result_span">₮ USDT</span>
                </p>
              )}
            </div>

            {showPopupEnd && (
              <div className="popup_overview_box">
                <p>{popupTexts[1]}</p>
              </div>
            )}
          </div>

          <div className="analytics_box">
            <div className="tltdash">
              <div
                className="info-icon-container"
                onClick={() => setShowPopupMonthlyPerformance(!showPopupStart)}
                onMouseEnter={() => setShowPopupMonthlyPerformance(true)}
                onMouseLeave={() => setShowPopupMonthlyPerformance(false)}
              >
                <p className="analytics_box_tlt">
                  {t("monthly_performance")}

                  <span className="faCircleInfo">
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </span>
                </p>
              </div>

              <li className="faCalendarDays">
                <FontAwesomeIcon icon={faChartLine} />
              </li>
            </div>
            <hr class="line"></hr>
            <div className="result">
              <div className="analytics_box_month_end_txt_cont">
                <p className="analytics_box_month_end_txt">
                  Last monthly Performance
                </p>

                <p className="result_value_txt">
                  {lastMonthlyPerformance}{" "}
                  <span className="usdt_result_span">₮ USDT</span>
                </p>
              </div>

              {isLoadingMonthlyPerformance ? (
                <div>
                  <LoadingStateSpinnerMonthlyPerformance />
                </div>
              ) : (
                <>
                  <p className="result_value_txt">
                    {formatNumberWithSingleQuoteOther(
                      parseFloat(monthlyPerformance)
                    )}
                    <span className="usdt_result_span">₮ USDT</span>
                  </p>
                  <p className="result_value_txt">
                    {monthlyPerformancePercentage.toFixed(2)}
                    <span className="usdt_result_span">%</span>
                  </p>
                </>
              )}
            </div>
            {showPopupMonthlyPerformance && (
              <div className="popup_overview_box">
                <p>{popupTexts[2]}</p>

                <p>{popupTexts[5]}</p>
              </div>
            )}
          </div>

          <div className="analytics_box">
            <div className="tltdash">
              <div
                className="info-icon-container"
                onClick={() => setShowPopupShareOfFund(!showPopupStart)}
                onMouseEnter={() => setShowPopupShareOfFund(true)}
                onMouseLeave={() => setShowPopupShareOfFund(false)}
              >
                <p className="analytics_box_tlt">
                  {t("share_of_fund")}
                  <span className="faCircleInfo">
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </span>
                </p>
              </div>

              <li className="faCalendarDays">
                <FontAwesomeIcon icon={faChartPie} />
              </li>
            </div>
            <hr class="line"></hr>
            <div className="result">
              {shareOfFund !== null ? (
                <>
                  <p className="result_value_txt">
                    {formatNumberWithSingleQuote(parseFloat(shareOfFund))}
                    <span className="usdt_result_span">₮ USDT</span>
                  </p>
                  <p className="result_value_txt_percent">
                    {((parseFloat(shareOfFund) / monthStart) * 100).toFixed(2)}
                    <span className="usdt_result_span">%</span>{" "}
                  </p>
                </>
              ) : (
                <p>Loading...</p>
              )}
            </div>
            <div className="analytics_box_footer">
              <button className="analytics_box_button">
                {" "}
                Increase your share
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  className="analytics_box_button_icon"
                />
              </button>
            </div>
            {showPopupShareOfFund && (
              <div className="popup_overview_box">
                <p>{popupTexts[3]}</p>
              </div>
            )}
          </div>

          <div className="analytics_box">
            <div className="tltdash">
              <div
                className="info-icon-container"
                onClick={() => setShowPopupProfitSplit(!showPopupProfitSplit)}
                onMouseEnter={() => setShowPopupProfitSplit(true)}
                onMouseLeave={() => setShowPopupProfitSplit(false)}
              >
                <p className="analytics_box_tlt">
                  {t("profit_share")}
                  <span className="faCircleInfo">
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </span>
                </p>
              </div>

              <div className="p_split_box">
                <p className="p_split_p">
                  <span className="p_split_span">{t("result_span_split")}</span>
                </p>
                <p className="split_value"> {profitSplit}%</p>
              </div>
              <li className="faCalendarDays">
                <FontAwesomeIcon icon={faMoneyBills} />
              </li>
            </div>
            <hr className="line"></hr>
            <div className="result">
              <div className="analytics_box_month_end_txt_cont">
                <p className="analytics_box_month_end_txt">Last profit share</p>

                <p className="result_value_txt">
                  {formatNumberWithSingleQuoteOther(
                    parseFloat(lastProfitShare)
                  )}

                  <span className="usdt_result_span">₮ USDT</span>
                </p>
              </div>
              {isLoadingProfitSplit || isLoadingProfitShare ? (
                <LoadingStateSpinnerProfitShare />
              ) : monthStart === monthEnd ? (
                <div>
                  <LoadingStateSpinnerProfitShare />
                </div>
              ) : (
                <p className="result_value_txt">
                  {profitShare !== null && !isNaN(profitShare) ? (
                    <>
                      {formatNumberWithSingleQuoteOther(
                        parseFloat(profitShare)
                      )}
                      <span className="usdt_result_span">₮ USDT</span>
                    </>
                  ) : null}
                </p>
              )}
            </div>
            {showPopupProfitSplit && (
              <div className="popup_overview_box">
                <p>{popupTexts[4]}</p>
              </div>
            )}
          </div>
        </div>

        <div className="overview_body">
          <div className="chart_section_2">
            <ProfitsBarChart userId={userId} />
          </div>
          <div className="chart_section">
            <div className="analytics_cont_section_1_profile_cont">
              <div className="analytics_cont_section_1_profile_cont_tittle">
                <p>Performance Chart</p>
              </div>
              <hr class="line"></hr>
              <div className="analytics_cont_section_1_profile_cont_body">
                <PerformanceChart />
                <div className="analytics_cont_section_1_profile_cont_body_list_box"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="overview_bottom">
          <div className="table_section">
            <div className="analytics_cont_section_1_profile_cont">
              <div className="analytics_cont_section_1_profile_cont_tittle">
                <p>Performance Analytics</p>
              </div>
              <hr class="line"></hr>

              <div className="analytics_cont_section_1_profile_cont_body">
                <PerformanceAnalytics />
              </div>
            </div>
          </div>

          <div className="table_section_2">
            <div className="analytics_cont_section_1_profile_cont_tittle">
              <p>Pay-outs</p>
            </div>
            <hr class="line"></hr>
            <p> Your pay-outs will be shown here</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
