import React, { useState, useEffect } from "react";
import axios from "axios";
import "./dashboard_components_css/ResetPassword.css";
import LogoRegister from "../../images/LogoRegister";
import { useTheme } from "../../CoinnectionThemes";
import { useParams } from "react-router-dom"; // Import useLocation
import Cookies from "js-cookie"; // Import Cookies

const ResetPassword = () => {
  const { token } = useParams(); // Get the token from the URL parameter
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const { darkMode } = useTheme();

  const authToken = Cookies.get("authToken"); // Get the authToken from cookies

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    try {
      // Make sure resetToken is not empty before using it
      if (!token) {
        setMessage("Invalid reset token.");
        return;
      }

      const response = await axios.post(
        `https://coinnection-server.onrender.com/api/reset-password/${token}`,
        {
          password,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the authToken in the headers
          },
        }
      );

      setMessage(response.data.message);
    } catch (error) {
      console.error("Password reset error:", error);
      setMessage("Error resetting password.");
    }
  };

  useEffect(() => {
    // Check if the user is authenticated or redirect them to the login page if not
    if (!authToken) {
      // Redirect logic here, for example:
      // window.location.href = "/login";
    }
  }, [authToken]);

  return (
    <div
      className={`reset_pass_request_section ${
        darkMode ? "dark-mode" : "white-mode"
      }`}
    >
      <div className="reset_pass_request_box">
        <form onSubmit={handlePasswordReset}>
          <div className="reset_pass_request_header">
            <LogoRegister />
          </div>

          <div className="reset_pass_request_header_welcome">
            <p className="reset_pass_welcome_tlt_txt_1">
              Change your password 🔓
            </p>
            <p className="reset_pass_welcome_txt_2">
              Please enter your new password below:
            </p>
          </div>
          <div className="reset_pass_subcont1">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter new password"
              required
            />
          </div>

          <div className="reset_pass_subcont1">
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm new password"
              required
            />
          </div>

          <div className="reset_pass_subcont1_buttons">
            <div className="submit_icon_txt">
              <button type="submit">Change password 🔓</button>
            </div>
          </div>
        </form>

        <p>{message}</p>
      </div>
    </div>
  );
};

export default ResetPassword;
