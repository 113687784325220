import React, { useState, useEffect } from "react";
import axios from "axios";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "../../CoinnectionThemes";

import "./css_user_auth_components/ReviewRegister.css";
import "./css_user_auth_components/RegisterPopUp.css";

import "../../UniversalStyles/UniversalStyles.css";
import LogoRegister from "../../images/LogoRegister";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const ReviewRegister = () => {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [username, setUsername] = useState("");
  const [investmentAmount, setInvestmentAmount] = useState("");
  const [profitSplit, setProfitSplit] = useState("");
  const [cryptoAddress, setCryptoAddress] = useState("");
  const [password, setPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [networkChain, setNetworkChain] = useState();
  const [countryResidence, setCountryResidence] = useState();
  const [investmentLockup, setInvestmentLockup] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const { darkMode, toggleDarkMode } = useTheme();
  const [referralCode, setReferralCode] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referralCodeFromLink = queryParams.get("referralCode");

  useEffect(() => {
    if (referralCodeFromLink) {
      setReferralCode(referralCodeFromLink);
    }
  }, [referralCodeFromLink]);

  useEffect(() => {
    document.title = "Register on Coinnection"; // Set the tab title
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (
      !name ||
      !lastName ||
      !email ||
      !password ||
      !confirmPassword ||
      !phoneNumber ||
      !username ||
      !investmentAmount ||
      !profitSplit ||
      !cryptoAddress ||
      !networkChain ||
      !countryResidence
    ) {
      return alert("All fields are required");
    }

    // Check if the password and confirm password match
    if (password !== confirmPassword) {
      setPasswordMatchError(true);
      setTimeout(() => {
        setPasswordMatchError(false);
      }, 5000); // Remove the error message after 5 seconds
      return;
    }

    // Reset the password match error state if it was previously shown
    setPasswordMatchError(false);

    const userData = {
      name,
      lastName,
      email,
      phoneNumber,
      password,
      username,
      investmentAmount,
      profitSplit,
      cryptoAddress,
      networkChain,
      countryResidence,
      investmentLockup,
      referralCode,
    };

    // Make the request to create the account
    axios
      .post(
        "https://coinnection-server.onrender.com/api/create-account",
        userData
      )
      .then((response) => {
        const userId = response.data.userId;
        console.log(`User registered with ID: ${userId}`);
        setShowMessage(true); // Show the pop-up message
      })
      .catch((error) => {
        console.error("Error creating account:", error);
        alert("Failed to create an account. Please try again later.");
      });
  };

  return (
    <div className={`register_page ${darkMode ? "dark-mode" : "white-mode"}`}>
      <div className="register_box">
        <div className="rgstr_logo_cont">
          <LogoRegister />
        </div>
        <div className="rgstr_txt_cont">
          <p className="sign_in_welcome_tlt_txt_1">
            Welcome to Coinnection Fund! 👋
          </p>
          <p className="sign_in_welcome_txt_2">
            Apply to join the Coinnection Fund
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="register_form_label">
            <div className="register_form_section_1">
              <input
                type="text"
                placeholder="First Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            <div className="register_form_section_2">
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />

              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="register_form_section_2">
              <div className="password-input-container">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="password-toggle-icon"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>

              <div className="password-input-container">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <FontAwesomeIcon
                  icon={showConfirmPassword ? faEyeSlash : faEye}
                  className="password-toggle-icon"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              </div>
            </div>

            <div className="register_form_section_3">
              <div className="register_form_section_3_1">
                <input
                  type="tel"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>

              <div className="register_form_section_3_1">
                <CountryDropdown
                  value={countryResidence}
                  onChange={(val) => setCountryResidence(val)}
                />
              </div>
            </div>
            <div className="register_form_section_4">
              <select
                value={profitSplit}
                onChange={(e) => setProfitSplit(e.target.value)}
              >
                <option value="">Select Package</option>
                <option value="40">
                  Starter: 1'000 USDT+ (40% Profit Share)
                </option>
                <option value="50"> Pro: 5'000 USDT+ (50% Profit Share)</option>
                <option value="55">
                  {" "}
                  Investor: 10'000 USDT+ (55% Profit Share)
                </option>
              </select>

              <input
                type="number"
                placeholder="Investment Amount"
                value={investmentAmount}
                onChange={(e) => setInvestmentAmount(e.target.value)}
              />
            </div>
            <div className="register_form_section_5">
              <select
                value={networkChain}
                onChange={(e) => setNetworkChain(e.target.value)}
              >
                <option value="">Select Chain</option>
                <option value="bsc">BSC Binance Smart Chain</option>
                <option value="matic"> MATIC Polygon</option>
                <option value="arb"> ARB Arbitrum</option>
                <option value="tron"> TRON Chain</option>
              </select>

              <input
                type="text"
                placeholder="USDT Wallet Address"
                value={cryptoAddress}
                onChange={(e) => setCryptoAddress(e.target.value)}
              />

              <input
                type="text"
                placeholder="Referral Code"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
              />
            </div>
          </div>
          <div className="terms_checkbox">
            <div className="terms_checkbox_box1">
              <input
                type="checkbox"
                id="investmentLockup"
                checked={investmentLockup}
                onChange={(e) => setInvestmentLockup(e.target.checked)}
              />

              <label htmlFor="investmentLockup">
                1-year investment lock-up
              </label>
            </div>
            <div className="terms_checkbox_box2">
              <label htmlFor="termsAccepted">
                <input
                  type="checkbox"
                  id="termsAccepted"
                  checked={termsAccepted}
                  onChange={() => setTermsAccepted(!termsAccepted)}
                />
                By clicking "Register", you agree to our{" "}
                <Link to="/terms" className="terms_link">
                  Terms & Conditions
                </Link>
              </label>
            </div>
          </div>
          <div className="rr_btn_div">
            <button type="submit">Register</button>
          </div>
        </form>

        {passwordMatchError && (
          <div className="password_match_error">
            Passwords don't match. Please try again.
          </div>
        )}
        {showMessage && (
          <div className="register_pop_up_dimmed">
            <div className="register_pop_up">
              <div className="register_pop_up_header">
                <div className="register_pop_up_header_box1">
                  <LogoRegister />
                </div>
                <div className="register_pop_up_header_box2">
                  <p>Thanks for your registration!</p>
                  <p>
                    Your personal dashboard will be activated soon, you will be
                    notified via e-mail.
                  </p>
                </div>
              </div>

              <div className="register_pop_up_body">
                <div className="register_pop_up_body_btns_cont">
                  <button className="register_pop_up_body_btns">
                    <a href="https://www.coinnection.net/">
                      Go back to homepage
                    </a>{" "}
                  </button>
                  <button className="register_pop_up_body_btns">
                    <a href="https://www.coinnection.net/dashboard">
                      Visit dashboard
                    </a>{" "}
                  </button>
                </div>
              </div>

              <div className="register_pop_up_footer">
                <p>©2023 Coinnection - All rights reserved</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewRegister;
