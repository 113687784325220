import React from "react";
import logo from "../../images/logo2.png";
import { Link } from "react-router-dom";

function LogoAffiliatePage() {
  return (
    <div className="logo">
      <img src={logo} alt="Coinnection Logo" />
      <Link to="/affiliate-home"> Affiliates</Link>
    </div>
  );
}

export default LogoAffiliatePage;
