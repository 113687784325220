import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./components/AffiliateHome.css";
import LogoAffiliatePage from "./components/LogoAffiliatePage";
import logo3 from "../images/logo3.png";

const AffiliateHome = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    document.title = "Coinnection Affiliates"; // Set the tab title
  }, []);

  const handleQuestionClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const faqData = [
    {
      question: "How do I join the Affiliate program?",
      answer:
        "Apply on our Affiliate page on coinnection.net/affiliates-signup and await your personal login to your affiliate dashboard.",
    },
    {
      question: "How do I recieve my persinal Affiliate link?",
      answer:
        "You will be able to create your own unique Affiliate link in the application process",
    },
    {
      question: "How is the Affiliates profit share calculated?",
      answer:
        "Your affiliates profit share is calculated by multiplying his share of fund with the monthly performance. The Investor recieves his split, according to the package he has chosen (40%, 50% or 55%) and the Coinnection Fund shares 10% of profits with you as our Affiliate.",
    },
    {
      question: "When do I get my affiliate pay-outs?",
      answer:
        "Affiliate pay-outs are paid out regularly at the end of each month to your personal pay-out address in USDT.",
    },
  ];

  return (
    <div className="affiliate_page_section">
      <div className="affiliate_top_menu_div">
        <div className="affiliate_logo">
          <LogoAffiliatePage />
        </div>
        <nav className="affiliate_top_menu">
          <ul className="affiliate_top_menu_ul">
            <li className="affiliate_top_menu_li">
              <Link to="/" className="signin_affiliate_link">
                Contact
              </Link>
            </li>

            <li className="affiliate_top_menu_li">
              <Link to="/" className="signin_affiliate_link">
                FAQ
              </Link>
            </li>

            <li className="affiliate_top_menu_li">
              <Link
                to="https://coinnection.net/affiliates-dashboard"
                className="signin_affiliate_btn"
              >
                {" "}
                Login
              </Link>
            </li>
            <li className="affiliate_top_menu_li">
              <Link
                to="https://coinnection.net/affiliates-signup"
                className="apply_affiliate_btn"
              >
                Apply
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      <div className="affiliate_body">
        <div className="affiliate_tittle">
          <p className="affiliate_tlt_1">
            Boost your Earnings through Coinnection's Affiliate Program
          </p>
          <p className="affiliate_tlt_2">
            Monetize your influence. Enhance your growth with strong analytics.
            Become part of a close community.
          </p>
          <div className="btn_div">
            <button className="affiliate_body_btn">
              <Link to="/affiliate-signup">Join our Affiliate program</Link>
            </button>
          </div>
        </div>
        <div className="affiliate_logo_right">
          {" "}
          <img src={logo3} alt="Coinnection Logo" />
        </div>
      </div>

      <div className="affiliate_body2">
        <div className="affiliate_commission_tlt">
          <p>Coinnection Affiliate Commission</p>
        </div>
        <div className="affiliate_commission_utlt">
          <p>
            {" "}
            Receive monthly revenue for every Investor you bring into
            Coinnection Fund.
          </p>
        </div>

        <div className="affiliate_commission_cont">
          <div className="commission_box">
            <div className="commission_box_tlt">
              {" "}
              <p>10% commission on affiliates profits</p>
            </div>

            <div className="commission_box_body">
              <ul>
                <li>
                  {" "}
                  The Coinnection Fund additionally offers an affiliate program,
                  meant for networkers and influencers.
                </li>
                <li>
                  {" "}
                  The affiliate has to register upfront via an online form in
                  order to be eligible for the affiliate program.
                </li>
                <li>
                  {" "}
                  Affiliates earn a 10% share from all sign-ups via their
                  personal link, and get paid monthly.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="affiliate_body3">
        <div className="affiliate_faq_tlt">
          <p>FAQ</p>
        </div>
        <div className="affiliate_faq_utlt">
          <p>Get familiar with our FAQ.</p>
        </div>

        <div className="affiliate_faq_cont">
          <div className="affiliate_faq_list">
            {faqData.map((faq, index) => (
              <div
                key={index}
                className={`affiliate_faq_item ${
                  activeIndex === index ? "active" : ""
                }`}
              >
                <div
                  className="affiliate_faq_question"
                  onClick={() => handleQuestionClick(index)}
                >
                  {faq.question}
                </div>
                {activeIndex === index && (
                  <div className="affiliate_faq_answer">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Rest of your component code */}
    </div>
  );
};

export default AffiliateHome;
