import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./dasboard.css";
import "./dashboard_pages/dashboard_components_css/side_menu_css/side_menu_container.css";
import Overview from "./dashboard_pages/overview_page/Overview";
import Settings from "./dashboard_pages/Settings_page/Settings";
import Login from "./user_auth_components/Login";
import Refferals from "./dashboard_pages/referral_page/Referrals";
import Notifications from "./dashboard_pages/Notification_page/Notifications";
import Analytics from "./dashboard_pages/overview_page/overview_components/Analytics";
import "./dark-mode.css";
import "./white-mode.css";

import { useTheme } from "../CoinnectionThemes";
import defaultProfileImage from "./logo1.png";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { Oval } from "react-loading-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDesktop,
  faUser,
  faGear,
  faRightFromBracket,
  faLightbulb,
  faMoon,
  faBell,
  faUsers,
  faLifeRing,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
axios.defaults.withCredentials = true;

const Dashboard = () => {
  const menuItemsWithIcons = {
    Dashboard: { name: "Dashboard", icon: faDesktop },
    Settings: { name: "Settings", icon: faGear },
    Referrals: { name: "Referrals", icon: faUsers },
    Notifications: { name: "Notifications", icon: faBell },

    // ... add other menu items as needed
  };

  const initialMenuItem = () => {
    const activeTab = Cookies.get("activeTab");
    return activeTab
      ? menuItemsWithIcons[activeTab]
      : menuItemsWithIcons["Dashboard"];
  };

  const [selectedMenuItem, setSelectedMenuItem] = useState(initialMenuItem());
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  const menuRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownAlertOpen, setDropdownAlertOpen] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [approved, setApproved] = useState(false);
  const { t, i18n } = useTranslation("Dashboard");
  const [loading, setLoading] = useState(true);
  const { darkMode, toggleDarkMode } = useTheme();
  const [selectedProfilePicture, setSelectedProfilePicture] = useState(null);

  const [userId, setUserId] = useState(null);

  const handleLoginCallback = (isLoggedIn, approved, userId) => {
    setIsLoggedIn(isLoggedIn);
    setApproved(approved);
    setUserId(userId); // Set the userId when the user logs in
  };

  const handleToggleDarkMode = () => {
    toggleDarkMode();
    localStorage.setItem("preferredTheme", darkMode ? "dark" : "white");
  };

  useEffect(() => {
    const activeTabFromCookie = Cookies.get("activeTab");
    console.log("Active Tab from Cookie:", activeTabFromCookie);
    if (activeTabFromCookie) {
      setSelectedMenuItem(menuItemsWithIcons[activeTabFromCookie]);
    }
  }, []);

  useEffect(() => {
    const preferredTheme = localStorage.getItem("preferredTheme");
    if (preferredTheme === "dark") {
      toggleDarkMode();
    }
  }, []);

  // Function to check login status
  const checkLoginStatus = async () => {
    try {
      const token = Cookies.get("authToken");

      if (!token) {
        setIsLoggedIn(false);
        setLoading(false); // Set loading to false when not logged in
        return;
      }

      const response = await axios.get(
        "https://coinnection-server.onrender.com/api/check-login",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { isLoggedIn, approved, username, userId } = response.data; // Extract username from the response
      setIsLoggedIn(isLoggedIn);
      setApproved(approved);
      setUsername(username);
      setUserId(userId);
      setLoading(false); // Set loading to false after getting user info
    } catch (error) {
      console.log("Error checking login status:", error);
      setIsLoggedIn(false);
      setUsername("");
      setApproved(false);
      setLoading(false); // Set loading to false in case of an error
    }
  };

  useEffect(() => {
    checkLoginStatus();
  }, []);

  // Function to check login status
  // useEffect to check login status when the component mounts
  useEffect(() => {
    document.title = "Dashboard Coinnection";
  }, []);

  // Function to handle logout
  const handleLogout = async () => {
    try {
      // Simply make the logout API request without credentials
      await axios.get("https://coinnection-server.onrender.com/api/logout");
      setIsLoggedIn(false);
      setUsername("");
      setApproved(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProfilePicture = async () => {
    try {
      const response = await axios.get(
        "https://coinnection-server.onrender.com/api/profile-picture",
        {
          responseType: "arraybuffer",
        }
      );

      const base64Image = Buffer.from(response.data, "binary").toString(
        "base64"
      );

      setProfilePicture(`data:image/*;base64,${base64Image}`);
    } catch (error) {
      console.error(error);
      setProfilePicture(defaultProfileImage);
    }
  };

  useEffect(() => {
    fetchProfilePicture();
  }, []);
  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    setSelectedProfilePicture(file);
  };

  const handleUploadProfilePicture = async () => {
    if (!selectedProfilePicture) {
      return;
    }

    const formData = new FormData();
    formData.append("profilePicture", selectedProfilePicture);

    try {
      await axios.post(
        "https://coinnection-server.onrender.com/api/upload-profile-picture",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    document.title = "Dashboard Coinnection";
  }, []);

  // Function to set the active tab in cookies
  const setActiveTabCookie = (activeTab) => {
    Cookies.set("activeTab", activeTab, {
      expires: 7,
      sameSite: "none",
      secure: true,
    });
  };

  const handleMenuItemClick = (menuItemKey) => {
    const menuItem = menuItemsWithIcons[menuItemKey];
    setSelectedMenuItem(menuItem);
    setActiveTabCookie(menuItemKey);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleAlertDropdown = () => {
    setDropdownAlertOpen((prevOpen) => !prevOpen);
  };
  useEffect(() => {
    const dropdownButton = document.querySelector(".profile_button");
    const dropdownContent = document.querySelector(".profile_dropdown_content");

    const handleClickOutside = (event) => {
      if (dropdownContent && !dropdownContent.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleAlertClickOutside = (event) => {
      const dropdownContent = document.querySelector(".alert_dropdown_content");

      if (dropdownContent && !dropdownContent.contains(event.target)) {
        setDropdownAlertOpen(false);
      }
    };

    document.addEventListener("mousedown", handleAlertClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleAlertClickOutside);
    };
  }, []);

  const fetchNotifications = async () => {
    try {
      const token = Cookies.get("authToken");
      const decoded = jwt_decode(token);
      const userId = decoded.userId;

      const response = await axios.get(
        `http://localhost:9000/api/user-notifications/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const unreadCount = response.data.filter((n) => !n.read_status).length;
      setUnreadNotifications(unreadCount);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <div
      className={`dashboard_container ${darkMode ? "dark-mode" : "white-mode"}`}
    >
      <div className="dashboard_container_left">
        <div className="side_menu_container" ref={menuRef}>
          <div className="side_menu_header_cont">
            <div className="side_menu_header_box_logo">
              <img
                src={require("./images/logo1.png")}
                alt="logo"
                className="logo_1"
              />
              <div className="side_menu_header_box_text">
                <p className="side_menu_header_box_text_company_name">
                  Coinnection
                </p>
              </div>

              <div className="side_menu_header_box_profile">
                <button className="profile_button" onClick={toggleDropdown}>
                  {profilePicture ? (
                    <FontAwesomeIcon
                      icon={faUser}
                      className="profile_pricture_default"
                    />
                  ) : (
                    // <img src={profilePicture} alt="Profile" className="profile_pricture_default" />
                    <img src={defaultProfileImage} alt="Default Profile" />
                  )}
                </button>
                {dropdownOpen && (
                  <ul
                    className={`profile_dropdown_content ${
                      dropdownOpen ? "open" : ""
                    }`}
                  >
                    <div className="profile_dropdown_content_header">
                      <div className="profile_dropdown_content_header_picture">
                        {profilePicture ? (
                          <FontAwesomeIcon
                            icon={faUser}
                            className="profile_pricture_default"
                          />
                        ) : (
                          <img
                            src={defaultProfileImage}
                            alt="Default Profile"
                          />
                        )}
                      </div>
                      <div className="profile_dropdown_content_header_cont">
                        <div className="profile_dropdown_content_header_cont_username">
                          <p className="profile_dropdown_content_header_username">
                            {username}
                          </p>
                        </div>

                        {/* <div className="profile_dropdown_content_header_cont_theme">
                          <button
                            className="theme-toggle-button"
                            onClick={handleToggleDarkMode}
                          >
                            <FontAwesomeIcon
                              icon={darkMode ? faLightbulb : faMoon}
                              className="theme_icon"
                            />
                          </button>
                        </div> */}
                      </div>
                    </div>
                    <div className="profile_dropdown_content_body">
                      <li onClick={() => handleMenuItemClick("Settings")}>
                        <FontAwesomeIcon icon={faGear} />
                        <span className="dropdown_menu_text">Settings</span>
                      </li>
                    </div>
                    <div className="profile_dropdown_content_body">
                      {/* <li onClick={handleLogout}>
                        <FontAwesomeIcon icon={faRightFromBracket} />
                        <span className="dropdown_menu_text">
                          Go back to Homepage
                        </span>
                      </li> */}

                      <li onClick={() => handleMenuItemClick("Help")}>
                        <FontAwesomeIcon icon={faLifeRing} />
                        <span className="dropdown_menu_text">Help</span>
                      </li>
                    </div>
                    <div className="profile_dropdown_content_footer">
                      <li onClick={handleLogout}>
                        <FontAwesomeIcon icon={faRightFromBracket} />
                        <span className="dropdown_menu_text">Log-out</span>
                      </li>
                    </div>
                  </ul>
                )}
              </div>
            </div>
          </div>

          <div className="side_menu_container_menu_tabs">
            <div className="side_menu_container_center">
              <ul>
                <li
                  className={
                    selectedMenuItem && selectedMenuItem.name === "Dashboard"
                      ? "active"
                      : ""
                  }
                  onClick={() => handleMenuItemClick("Dashboard")}
                >
                  <FontAwesomeIcon icon={faDesktop} />
                  <span className="menu-text">{t("overview_tab_txt")}</span>
                </li>
                <hr class="line_3"></hr>

                <li
                  className={
                    selectedMenuItem.name === "Referrals" ? "active" : ""
                  }
                  onClick={() => handleMenuItemClick("Referrals")}
                >
                  <FontAwesomeIcon icon={faUsers} />
                  <span className="menu-text">Referral Program</span>
                </li>
                <li
                  className={
                    selectedMenuItem.name === "Notifications" ? "active" : ""
                  }
                  onClick={() => handleMenuItemClick("Notifications")}
                >
                  <FontAwesomeIcon icon={faBell} />
                  {unreadNotifications > 0 && (
                    <span className="notification_dot"></span>
                  )}
                  <span className="menu-text">Notifications</span>
                </li>
              </ul>
            </div>
            <div className="side_menu_container_bottom">
              <ul>
                <li
                  className={
                    selectedMenuItem.name === "Settings" ? "active" : ""
                  }
                  onClick={() => handleMenuItemClick("Settings")}
                >
                  <FontAwesomeIcon icon={faGear} />
                  <span className="menu-text">{t("settings_tab_txt")}</span>
                </li>

                <li
                  className={selectedMenuItem === "Help" ? "active" : ""}
                  onClick={() => handleMenuItemClick("Settings")}
                >
                  <FontAwesomeIcon icon={faLifeRing} />
                  <span className="menu-text">{t("help_tab_txt")}</span>
                </li>
              </ul>

              {/* <div className="side_menu_container_bottom_box_actions">
                <button
                  className="profile_button_alert"
                  onClick={toggleAlertDropdown}
                >
                  <FontAwesomeIcon icon={faBell} className="top_menu_icon" />
                </button>

                {dropdownAlertOpen && (
                  <ul
                    className={`alert_dropdown_content ${
                      dropdownAlertOpen ? "open" : ""
                    }`}
                  >
                    <li>
                      <FontAwesomeIcon icon={faBell} />
                      <span className="dropdown_menu_text_alert">
                        notification!
                      </span>
                    </li>
                  </ul>
                )}
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard_container_right">
        <div className="dashboard_container_right_header">
          {selectedMenuItem && (
            <>
              <div className="dashboard_container_right_header_box">
                <FontAwesomeIcon
                  icon={selectedMenuItem.icon}
                  className="header-icon"
                />
              </div>

              <div className="dashboard_container_right_header_box_text">
                {/* Display icon */}
                <p>{selectedMenuItem.name}</p> {/* Display name */}
              </div>
            </>
          )}
        </div>
        {isLoggedIn ? (
          approved ? (
            <div className="content-container">
              {selectedMenuItem && selectedMenuItem.name === "Dashboard" && (
                <Overview userId={userId} />
              )}
              {/* Uncomment and update the line below if you have an "Analytics" tab */}
              {/* {selectedMenuItem && selectedMenuItem.name === "Analytics" && <Analytics />} */}
              {selectedMenuItem &&
                selectedMenuItem.name === "Referrals" && ( // Corrected the name here
                  <Refferals />
                )}

              {selectedMenuItem &&
                selectedMenuItem.name === "Notifications" && ( // Corrected the name here
                  <Notifications />
                )}

              {selectedMenuItem && selectedMenuItem.name === "Settings" && (
                <Settings />
              )}
            </div>
          ) : (
            <div className="approval_container">
              <div className="approval_container_box">
                <div className="approval_container_box_header">
                  <li className="approval_header_icon_li">
                    <Oval className="approval_header_icon" />
                  </li>
                </div>
                <p className="approval_pending_txt">
                  Account approval pending.
                </p>
                <p>Please wait for manual approval.</p>
              </div>
            </div>
          )
        ) : (
          <div
            className={`signin_cont ${isLoggedIn ? "" : "dimmed-background"}`}
          >
            <Login onLogin={handleLoginCallback} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
