import React, { useState } from "react";
import axios from "axios";
import "./css_user_auth_components/login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import LogoRegister from "../../images/LogoRegister";
import { useTheme } from "../../CoinnectionThemes";
import LoadingSpinner_login from "../../LoadingStateSpinner_login";
import Cookies from "js-cookie";

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { darkMode } = useTheme();
  const [loading, setLoading] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      const response = await axios.post(
        "https://coinnection-server.onrender.com/api/login",
        { email, password },
        { withCredentials: true } // Include credentials option
      );

      if (response.data.message === "Login successful") {
        // Set a cookie for authentication
        Cookies.set("authToken", response.data.token, {
          expires: 1, // Set the expiration time as needed
          secure: true,
          sameSite: "none",
          // domain: "localhost",
          domain: ".coinnection.net",
        });

        // Fetch the updated login status immediately
        await checkLoginStatus();
        setError(null);
      } else {
        setError("Invalid email or password");
      }
    } catch (error) {
      setError("Invalid email or password");
    } finally {
      setLoading(false);
    }
  };

  const checkLoginStatus = async () => {
    try {
      const token = Cookies.get("authToken");

      if (!token) {
        onLogin(false, false);
        return;
      }

      const response = await axios.get(
        "https://coinnection-server.onrender.com/api/check-login",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { isLoggedIn, approved } = response.data;
      onLogin(isLoggedIn, approved);
    } catch (error) {
      console.log("Error checking login status:", error);
      onLogin(false, false);
    }
  };
  return (
    <div className={`sign_in_section ${darkMode ? "dark-mode" : "white-mode"}`}>
      <div className="sign_in_box">
        {error && <p>{error}</p>}
        {loading ? (
          <LoadingSpinner_login />
        ) : (
          <form onSubmit={handleLogin}>
            <div className="sign_in_cont_header">
              <LogoRegister />
            </div>

            <div className="sign_in_cont_header_welcome">
              <p className="sign_in_welcome_tlt_txt_1">
                Welcome back to the Coinnection Fund 👋
              </p>
              <p className="sign_in_welcome_txt_2">
                Log-in to your account to stay #coinnected.
              </p>
            </div>
            <div className="sign_in_subcont1">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
            </div>
            <div className="sign_in_subcont1">
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
            </div>
            <div className="sign_in_subcont1_forgot_password">
              <p>
                <a href="https://coinnection.net/forgot-password">
                  Forgot password?
                </a>
              </p>
            </div>
            <div className="sign_in_subcont1_buttons">
              <div className="submit_icon">
                <FontAwesomeIcon className="icon_envelope" icon={faEnvelope} />
              </div>

              <div className="submit_icon_txt">
                <button type="submit">Log-in</button>
              </div>
            </div>
            <div className="sign_up_noacc_cont">
              <p>
                No account yet?{" "}
                <a href="https://coinnection.net/registration">Sign up here</a>
              </p>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
