import React, { useState } from "react";
import axios from "axios";
import "./css_Settings_page/PasswordChangeForm.css";
import { useTheme } from "../../../CoinnectionThemes";
import Cookies from "js-cookie"; //

const PasswordChangeForm = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [message, setMessage] = useState(null);
  const { darkMode, toggleDarkMode } = useTheme();

  const authToken = Cookies.get("authToken"); // Get the authToken from cookies

  const handleChangePassword = () => {
    // Check if the new password and confirm new password match
    if (newPassword !== confirmNewPassword) {
      setMessage("New passwords do not match");
      return;
    }

    // Send the request to change the password
    axios
      .post(
        "https://coinnection-server.onrender.com/api/change-password",
        {
          currentPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setMessage("Password changed successfully");
      })
      .catch((error) => {
        console.error("Failed to change password:", error);
        setMessage("Failed to change password");
      });
  };

  return (
    <div
      className={`password_change_cont ${
        darkMode ? "dark-mode" : "white-mode"
      }`}
    >
      <div className="settings_cont_section_div_body_cont_name">
        <p className="settings_cont_section_div_body_cont_name_text_title">
          Password
        </p>
      </div>
      {message && <p>{message}</p>}
      <div className="settings_cont_section_div_body_cont_name">
        <p className="settings_cont_section_div_body_cont_name_text">
          Current Password
        </p>
      </div>
      <input
        type="password"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
      />

      <div className="settings_cont_section_div_body_cont_name">
        <p className="settings_cont_section_div_body_cont_name_text">
          New Password
        </p>
      </div>
      <input
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <p className="settings_cont_section_div_body_cont_name_input_below">
        Password must contain a letter and a number and be a minimum of 8
        characters.
      </p>

      <div className="settings_cont_section_div_body_cont_name">
        <p className="settings_cont_section_div_body_cont_name_text">
          Confirm New Password
        </p>
      </div>
      <input
        type="password"
        value={confirmNewPassword}
        onChange={(e) => setConfirmNewPassword(e.target.value)}
      />
      <button onClick={handleChangePassword}>Change Password</button>
    </div>
  );
};

export default PasswordChangeForm;
