// ThemeContext.js
import React, { createContext, useContext, useState } from "react";

const CoinnectionThemes = createContext();

export const useTheme = () => {
  return useContext(CoinnectionThemes);
};

const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(true);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <CoinnectionThemes.Provider value={{ darkMode, toggleDarkMode }}>
      {children}
    </CoinnectionThemes.Provider>
  );
};

export default ThemeProvider;
