import React, { useState, useEffect } from "react";
import moment from "moment";
import "./Clock.css"; // Import the CSS file

const Clock = () => {
  const [timerData, setTimerData] = useState({
    day: "",
    hours: "",
    minutes: "",
    seconds: "",
  });

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const updateTime = () => {
      setTimerData({
        day: moment().format("D"), // Use "D" to get the day of the month
        hours: moment().format("k"),
        minutes: moment().format("mm"),
        seconds: moment().format("ss"),
      });
      requestAnimationFrame(updateTime);
    };

    updateTime();
  }, []);

  useEffect(() => {
    const calculateCountdown = () => {
      const now = new Date();
      const endOfMonth = new Date(
        now.getFullYear(),
        now.getMonth() + 1,
        0,
        23,
        59,
        59,
        999
      );
      const remainingTime = endOfMonth.getTime() - now.getTime();

      const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    };

    const interval = setInterval(() => {
      calculateCountdown();
    }, 1000);

    calculateCountdown();

    return () => clearInterval(interval);
  }, []);

  const countdownString = `${countdown.days}d ${countdown.hours}h ${countdown.minutes}m ${countdown.seconds}s`;
  // Format the current year and month
  const currentYear = moment().format("YYYY");
  const currentMonth = moment().format("MMMM");

  return (
    <div className="clock_box">
      <div className="clock-container">
        <div className="date_sub_box">
          <p className="date-label">{currentYear}</p>
          <p className="date-label">{currentMonth}</p>
        </div>
        <div className="clock_sub_box">
          <div className="clock-col">
            <p className="clock-day clock-timer">{timerData.day}</p>
            <p className="clock-label">Day</p>
          </div>
          <div className="clock-col">
            <p className="clock-hours clock-timer">{timerData.hours}</p>
            <p className="clock-label">Hours</p>
          </div>
          <div className="clock-col">
            <p className="clock-minutes clock-timer">{timerData.minutes}</p>
            <p className="clock-label">Minutes</p>
          </div>
          <div className="clock-col">
            <p className="clock-seconds clock-timer">{timerData.seconds}</p>
            <p className="clock-label">Seconds</p>
          </div>
        </div>
      </div>
      <div className="clock-container">
        <div className="date_sub_box">
          <p className="countdown_label">Next pay-out:</p>
        </div>
        <div className="countdown_col">
          <p className="clock-label">Countdown</p>
          <p className="clock-countdown countdown-timer">{countdownString}</p>
        </div>
      </div>
    </div>
  );
};

export default Clock;
