import React, { useEffect, useState } from "react";
import "./CustomSpinner.css"; // Import your CSS file
import { useTranslation } from "react-i18next";
import { useTheme } from "./CoinnectionThemes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const LoadingStateSpinnerProfitShare = ({ color }) => {
  const { t } = useTranslation();
  const { darkMode, toggleDarkMode } = useTheme();
  const [showPopupStart, setShowPopupStart] = useState(false);

  const spinnerStyle = {
    borderTopColor: color,
  };

  const popupTexts = [t("LoadingStates:popupTexts_0_2")];

  return (
    <div
      className={`loading_monthly_performance ${
        darkMode ? "dark-mode" : "white-mode"
      }`}
    >
      <p className="analytics_box_month_end_txt">Next Share of Fund</p>
      <div className="loading_monthly_performance_text">
        <div
          className="info-icon-container"
          onClick={() => setShowPopupStart(!showPopupStart)}
          onMouseEnter={() => setShowPopupStart(true)}
          onMouseLeave={() => setShowPopupStart(false)}
        >
          <div className="process_cont">
            <div className="process_txt_cont">
              <p className="analytics_box_month_end_txt_result">
                {t("LoadingStates:month_end_text_process_txt")}
              </p>
            </div>
            <div className="custom_spinner" style={spinnerStyle}>
              <div className="spinner_inner"></div>
            </div>

            <span className="faCircleInfo">
              <FontAwesomeIcon icon={faCircleInfo} />
            </span>
          </div>
        </div>
      </div>

      {showPopupStart && (
        <div className="popup_overview_box_spinner">
          <p>{popupTexts[0]}</p>
        </div>
      )}
    </div>
  );
};

export default LoadingStateSpinnerProfitShare;
