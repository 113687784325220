import React, { useState } from "react";
import axios from "axios";

const SystemNotifications = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("http://localhost:9000/api/send-system-notifications", {
        title,
        message,
      });
      setTitle("");
      setMessage("");
      // Handle successful submission (e.g., showing a success message)
    } catch (error) {
      // Handle errors (e.g., showing an error message)
    }
  };

  return (
    <div className="system-notifications-form">
      <h2>Create System Notification</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div>
          <label>Message</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default SystemNotifications;
