import React from "react";
import "./Terms.css";

const Terms = () => {
  return (
    <div className="terms_page_cont">
      <p className="page_terms_tlt">Coinnection Fund Terms and Conditions</p>
      <p className="terms_tlt">Introduction</p>
      <p className="terms_utlt">
        Purpose and scope of the Terms and Conditions
      </p>
      <p className="terms_description">
        The purpose of these Terms and Conditions ("Terms") is to outline the
        relationship between Coinnection Fund ("Fund") and its investors
        ("Investors"), covering the investment agreement, management of funds,
        and redemption of initial investments as well as profit pay-outs. The
        Terms cover all aspects of the Fund's operations, including investment
        objectives, fees, risk factors, management, reporting, confidentiality
        and termination.
        <p>
          By investing into the Fund, Investors agree to these Terms, which may
          be amended periodically. The Fund may modify the Terms as needed, and
          Investors will be notified of any changes. These Terms should be read
          with the Fund's offering documents, which contain detailed information
          about the Fund and its strategies.
        </p>
      </p>
      <p className="terms_tlt">General Provisions</p>
      <p className="terms_utlt">
        Acceptance of Terms and Conditions
        {/* ... */}
      </p>

      <p className="terms_description">
        By investing into the Fund, Investors confirm they have read,
        understood, and accepted the Terms and Conditions. Investing in the Fund
        implies adherence to the Terms, including any changes. Acceptance of the
        Terms is required for participation. The Fund reserves the right to
        decline an Investor's participation at its discretion, considering the
        best interests of the Fund.
      </p>

      <p className="terms_dscptn_utlt">
        Amendments and Modifications
        {/* ... */}
      </p>

      <p className="terms_description">
        The Fund reserves the right to update, modify, or amend these Terms and
        Conditions at its own discretion. Investors will be notified of any
        material changes to the Terms by mail. Continued participation in the
        Fund after any changes implies acceptance of the updated Terms.
      </p>
      <p className="terms_tlt">Fund Operation</p>
      <p className="terms_utlt">
        Investment objective and strategy
        {/* ... */}
      </p>

      <p className="terms_description">
        The Coinnection Fund aims to generate long- as well as short-term
        capital growth through our multi-strategy approach. This includes
        holding long/short positions, trading event-driven developments,
        reacting on global macro changes and acting upon the current trend. The
        Fund focuses mainly on day trading and capitalizes on market
        inefficiencies across diverse assets and geographies, while employing
        disciplined risk management at all times.
      </p>

      <p className="terms_dscptn_utlt">
        Risk Factors
        {/* ... */}
      </p>

      <p className="terms_description">
        Investing in general implies great risks, including market fluctuations,
        liquidity issues, leverage, operational challenges, regulatory changes,
        third-party defaults and external factors. Investors have to carefully
        consider these risks before investing.
      </p>

      <p className="terms_dscptn_utlt">
        Management of the fund
        {/* ... */}
      </p>

      <p className="terms_description">
        The Coinnection Fund is managed and traded by its two founders,
        overseeing the Funds day- to-day operations, risk management, investment
        strategies, and regulatory compliance. The Fund acts in the Investors'
        best interests as well as its own, leveraging expertise to achieve
        investment objectives while managing risks and maximizing returns.
      </p>

      <p className="terms_tlt">Investment in the Coinnection Fund</p>
      <p className="terms_utlt">
        Participation process
        {/* ... */}
      </p>

      <p className="terms_description">
        In order to invest in the Coinnection Fund, Investors must review the
        offering documents, fill out the investment agreement and agree to the
        terms and conditions. After review and due diligence, approved Investors
        will receive a personal login and instructions to deposit funds. Upon
        receipt of funds, Investors will receive confirmation of their
        investment.
      </p>

      <p className="terms_dscptn_utlt">
        Redemption Process
        {/* ... */}
      </p>

      <p className="terms_description">
        To redeem the initial investment, Investors must activate the
        “Redemption” button in their dashboard. This will trigger the 90-day
        notice period starting at the end of the month. The effective pay-back
        will be made at the end of the notice period.
      </p>

      <p className="terms_dscptn_utlt">
        Assignment of Shares
        {/* ... */}
      </p>

      <p className="terms_description">
        Each Investor has insight about his share of the Fund in his dashboard.
        According to his share of the Fund, the profits will be adequately
        assigned at the end of the month and paid out according to his agreed
        profit share.
      </p>

      <p className="terms_dscptn_utlt">
        Prohibited Countries
        {/* ... */}
      </p>

      <p className="terms_description">
        By using the Platform, you agree that it should not be accessed or used
        in jurisdictions where it is prohibited by law or where we are not
        authorized to provide our services. We do not offer services in certain
        jurisdictions, including: the United States, mainland China, Singapore,
        Canada, North Korea, Cuba, Uzbekistan, Iran, Russian-controlled regions
        of Ukraine and Syria. If it is discovered that you provided false
        information about your location or residence, the Fund may take
        appropriate action, including terminating your account and closing our
        business relationship, including redemption of your investment.
      </p>

      <p className="terms_description"></p>
      <p className="terms_tlt">Fees and Expenses</p>
      <p className="terms_utlt">
        Transaction fees
        {/* ... */}
      </p>

      <p className="terms_description">
        Transaction fees for monthly pay-outs are not charged to the Investors,
        but subtracted from the Funds’ part of the profits, as long as the
        conventional blockchain networks are being used as provided by the Fund.
      </p>

      <p className="terms_dscptn_utlt">
        Fees for early redemption
        {/* ... */}
      </p>

      <p className="terms_description">
        If the Investor has chosen the 1-year investment lock-up and decides to
        withdraw his initial investment earlier than agreed to, there will be
        two different penalties adapted:
      </p>
      <p>
        1. Subtraction of the additional 5% profit share during the 90-day
        notice period.
      </p>
      <p>
        2. 5% management fee, deducted from initial investment upon payback.{" "}
      </p>

      <p className="terms_tlt">Reporting and Communication</p>
      <p className="terms_utlt">
        Periodic reporting
        {/* ... */}
      </p>

      <p className="terms_description">
        The Fund will regularly report to Investors on the Fund’s performance,
        activities, and finances via the Investors dashboard. Reports will
        include information on the current Fund value, monthly performance, new
        additions and significant developments.
      </p>

      <p className="terms_tlt">Confidentiality and Data Protection</p>
      <p className="terms_utlt">
        Confidentiality Obligations
        {/* ... */}
      </p>

      <p className="terms_description">
        The Fund shall keep all information provided by the Investor
        confidential, using it only to manage the Fund's operations. The Fund
        shall protect this information and not disclose it to third parties.
        Conversely, Investors shall also maintain the confidentiality of
        Fund-related information and not share it with third parties without the
        Fund's explicit consent.
      </p>

      <p className="terms_dscptn_utlt">
        Data Protection and Privacy
        {/* ... */}
      </p>

      <p className="terms_description">
        The Fund collects and processes Investor data for investment management
        and compliance purposes, and takes measures to ensure data security and
        confidentiality. Personal data is not disclosed to third parties except
        as necessary for regulatory reasons. Investors have rights to access,
        rectify, or erase their data. The Fund’s dashboard uses cookies for
        website functionality, and Investors may disable them in their browser
        settings.
      </p>

      <p className="terms_tlt">Termination & Liquidation</p>
      <p className="terms_utlt">
        Termination by the Investor
        {/* ... */}
      </p>

      <p className="terms_description">
        The Investor may terminate the Investors agreement anytime via written
        notice or by clicking the “Redemption” button in his dashboard.
        Termination of the investment will trigger the 90-day notice period,
        starting at the end of the month. During the notice period the profits
        will be distributed in the usual manner until the initial investment is
        fully paid back at the end of the notice period. This signals the end of
        the relationship between the Investor and the Coinnection Fund.
      </p>

      <p className="terms_dscptn_utlt">
        Termination by the Fund
        {/* ... */}
      </p>

      <p className="terms_description">
        The Fund may terminate an Investor relationship at any time, without
        cause, by providing written notice to the Investor. The termination will
        be effective as of the date specified in the notice. In this case, all
        accrued profits as well as the initial investment will be fully paid out
        to the Investor at the end of the relationship.
      </p>

      <p className="terms_dscptn_utlt">
        Liquidation Process
        {/* ... */}
      </p>

      <p className="terms_description">
        In case of a liquidation, the Fund's assets will be sold and distributed
        to Investors based on their respective interests. The Fund's management
        will notify the Investors promptly and provide relevant information. The
        liquidation can occur at the Fund's discretion or in certain events such
        as an event of default.
      </p>

      <p className="terms_tlt">Miscellaneous Provisions</p>
      <p className="terms_utlt">
        Force majeure
        {/* ... */}
      </p>

      <p className="terms_description">
        Force Majeure refers to events beyond the control of the Coinnection
        that prevent it from fulfilling its obligations under these Terms and
        Conditions. The Fund is not liable for any delay or failure due to Force
        Majeure events and will strive to minimize their impact. The Fund will
        notify its Investors of any Force Majeure event and may liquidate the
        Fund if the event persists.
      </p>

      <p className="terms_dscptn_utlt">
        Entire Agreement
        {/* ... */}
      </p>

      <p className="terms_description">
        These Terms and Conditions represent the entire agreement between the
        Fund and Investors, replacing any previous agreements on the same
        subject. Any changes to the Terms must be made in writing and signed by
        authorized representatives of the Fund and the Investor to be valid.
      </p>
    </div>
  );
};

export default Terms;
