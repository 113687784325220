import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./CommissionPageAffiliate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandshake,
  faGlobe,
  faCommentsDollar,
} from "@fortawesome/free-solid-svg-icons";

const CommissionPageAffiliate = () => {
  return (
    <div className="CommissionPageAffiliate_section">
      <div className="CommissionPageAffiliate_section_body2">
        <div className="affiliate_commission_tlt">
          <p>Coinnection Affiliate program</p>
        </div>
        <div className="affiliate_commission_utlt">
          <p>
            {" "}
            Receive monthly revenue for every Investor you bring into
            Coinnection Fund.
          </p>
        </div>

        <div className="CommissionPageAffiliate_cont">
          <div className="CommissionPageAffiliate_box">
            <div className="CommissionPageAffiliate_box_header">
              <li className="iconCommissionPageAffiliate">
                <FontAwesomeIcon icon={faGlobe} />
              </li>
            </div>
            <hr className="line2"></hr>
            <div className="CommissionPageAffiliate_box_body">
              <p>
                The affiliate program was specifically designed for networkers
                and influencers, navigating through the world of blockchain and
                finance.
              </p>
            </div>
          </div>

          <div className="CommissionPageAffiliate_box">
            <div className="CommissionPageAffiliate_box_header">
              <li className="iconCommissionPageAffiliate">
                <FontAwesomeIcon icon={faCommentsDollar} />
              </li>
            </div>
            <hr className="line2"></hr>
            <div className="CommissionPageAffiliate_box_body">
              <p>
                You receive 10% monthly Profit Share for every affiliate that
                signs up with your personal partner link. Get your own affiliate
                dashboard now and start earning!
              </p>
            </div>
          </div>

          <div className="CommissionPageAffiliate_box">
            <div className="CommissionPageAffiliate_box_header">
              <li className="iconCommissionPageAffiliate">
                <FontAwesomeIcon icon={faHandshake} />
              </li>
            </div>
            <hr className="line2"></hr>
            <div className="CommissionPageAffiliate_box_body">
              <p>
                Month by month, from Investor to Investor – we are building
                lasting connections, not just customers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommissionPageAffiliate;
