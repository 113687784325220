import React, { useState } from "react";
import "./Notifications.css";
import All_Notifications from "./components/All_Notifications";
import System_notifications from "./components/System_notifications"; // Corrected import
import Profit_notifications from "./components/Profit_notifications"; // Corrected import

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faMoneyCheck,
  faDesktop,
} from "@fortawesome/free-solid-svg-icons";

const Notifications = ({ userId }) => {
  const [activeTab, setActiveTab] = useState("all");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="notifications_cont">
      <div className="side_bar_notifications">
        <ul>
          <li
            className={activeTab === "all" ? "active" : ""}
            onClick={() => handleTabClick("all")}
          >
            <FontAwesomeIcon icon={faBell} />
            <span className="menu-text">All Notifications</span>
          </li>
          <li
            className={activeTab === "payout" ? "active" : ""}
            onClick={() => handleTabClick("payout")}
          >
            <FontAwesomeIcon icon={faMoneyCheck} />
            <span className="menu-text">Profit Notifications</span>
          </li>
          <li
            className={activeTab === "system" ? "active" : ""}
            onClick={() => handleTabClick("system")}
          >
            <FontAwesomeIcon icon={faDesktop} />
            <span className="menu-text">System Notifications</span>
          </li>
        </ul>
      </div>
      <div className="content_notifications">
        {activeTab === "all" && <All_Notifications userId={userId} />}
        {activeTab === "payout" && <Profit_notifications userId={userId} />}
        {activeTab === "system" && <System_notifications />}
      </div>
    </div>
  );
};

export default Notifications;
