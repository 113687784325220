import React from "react";

import TopMenuAffiliate from "./components/TopMenuAffiliate";
import StartPageAffiliate from "./components/StartPageAffiliate";
import CommissionPageAffiliate from "./components/CommissionPageAffiliate";
import FooterAffiliate from "./components/FooterAffiliate";

import "../Webpage/JoinFund.css";

function AffiliateIndex() {
  return (
    <div className="fund_page">
      <div className="scroll_section_affiliate">
        <TopMenuAffiliate />
      </div>
      <section className="scroll-section" id="home">
        <StartPageAffiliate />
      </section>

      <section className="scroll-section" id="home">
        <CommissionPageAffiliate />
      </section>

      <FooterAffiliate />
    </div>
  );
}

export default AffiliateIndex;
