import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./Dashboard/dashboard_pages/overview_page/overview_white_mode.css";
import "./Dashboard/dashboard_pages/referral_page/Referrals_white_mode.css";
import "./Dashboard/dashboard_pages/dashboard_components_css/TopMenuDashboard_white_theme.css";
import "./Dashboard/dashboard_pages/overview_page/overview_components/analytics_components/PerformanceAnalytics_white_theme.css";
import "./Dashboard/dashboard_pages/Settings_page/css_Settings_page/Settings_white_theme.css";
import "./Dashboard/dashboard_pages/Settings_page/css_Settings_page/PasswordChangeForm._white_theme.css";
import "./Dashboard/dashboard_pages/Settings_page/css_Settings_page/RedemptionForm_white_theme.css";
import "./UniversalStyles/UniversalStyles_white_theme.css";

import "./Dashboard/user_auth_components/css_user_auth_components/login_white_theme.css";
import "./Dashboard/user_auth_components/css_user_auth_components/ReviewRegister_white_theme.css";
import "./CustomSpinner_white_mode.css";
import AffiliateIndex from "./affiliate_components/AffiliateIndex";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import ThemeProvider from "./CoinnectionThemes";
import reportWebVitals from "./reportWebVitals";
import Dashboard from "./Dashboard/Dashboard";
import ForgotPassword from "./Dashboard/dashboard_pages/ForgotPassword";
import ResetPassword from "./Dashboard/dashboard_pages/ResetPassword";
import Onboarding from "./Webpage/Webpage_components/onboarding_components/Onboarding";
import FaqAffiliate from "./affiliate_components/components/FaqAffiliate";
import Contact from "./Webpage/Webpage_components/Contact";
import AffiliateDashboard from "./affiliate_components/AffiliateDashboard";
import AffiliateHome from "./affiliate_components/AffiliateHome";
import AffiliateRegister from "./affiliate_components/components/AffiliateRegister";
import InternDashboard from "./intern_page/InternDashboard";
import ReviewRegister from "./Dashboard/user_auth_components/ReviewRegister";
import JoinFund from "./Webpage/JoinFund";
import Terms from "./components/Terms";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./Webpage/Webpage_components/locales/en.json"; // Your English translations
import de from "./Webpage/Webpage_components/locales/de.json"; // Your German translations
import es from "./Webpage/Webpage_components/locales/es.json"; // Your Spanish translations

import enStartJoinFund from "./locales/StartJoinFund/en.json"; // Import the translation file for StartJoinFund component
import deStartJoinFund from "./locales/StartJoinFund/de.json"; // Import the translation file for StartJoinFund component
import esStartJoinFund from "./locales/StartJoinFund/es.json"; // Import the translation file for StartJoinFund component

import enPvision from "./locales/Pvision/en.json"; // Corrected import path for English
import dePvision from "./locales/Pvision/de.json"; // Corrected import path for German
import esPvision from "./locales/Pvision/es.json"; // Corrected import path for Spanish

import enFundAllocation from "./locales/FundAllocation/en.json"; // Corrected import path for English
import deFundAllocation from "./locales/FundAllocation/de.json"; // Corrected import path for German
import esFundAllocation from "./locales/FundAllocation/es.json"; // Corrected import path for Spanish

import enServices from "./locales/Services/en.json"; // Corrected import path for English
import deServices from "./locales/Services/de.json"; // Corrected import path for German
import esServices from "./locales/Services/es.json"; // Corrected import path for Spanish

import enFundReason from "./locales/FundReason/en.json"; // Corrected import path for English
import deFundReason from "./locales/FundReason/de.json"; // Corrected import path for German
import esFundReason from "./locales/FundReason/es.json"; // Corrected import path for Spanish

import enGettingStarted from "./locales/GettingStarted/en.json"; // Corrected import path for English
import deGettingStarted from "./locales/GettingStarted/de.json"; // Corrected import path for German
import esGettingStarted from "./locales/GettingStarted/es.json"; // Corrected import path for Spanish

import enAffiliateRefferal from "./locales/AffiliateRefferal/en.json"; // Corrected import path for English
import deAffiliateRefferal from "./locales/AffiliateRefferal/de.json"; // Corrected import path for German
import esAffiliateRefferal from "./locales/AffiliateRefferal/es.json"; // Corrected import path for Spanish

import enFaq from "./locales/Faq/en.json";
import deFaq from "./locales/Faq/de.json";
import esFaq from "./locales/Faq/es.json";

import enFaqAffiliate from "./locales/FaqAffiliate/en.json";
import deFaqAffiliate from "./locales/FaqAffiliate/de.json";
import esFaqAffiliate from "./locales/FaqAffiliate/es.json";

import enTerms from "./locales/Terms/en.json";
import deTerms from "./locales/Terms/de.json";
import esTerms from "./locales/Terms/es.json";

import enContact from "./locales/Contact/en.json";
import deContact from "./locales/Contact/de.json";
import esContact from "./locales/Contact/es.json";

import enDashboard from "./locales/Dashboard/dashboard_page/en.json"; // Import the translation file for StartJoinFund component
import deDashboard from "./locales/Dashboard/dashboard_page/de.json"; // Import the translation file for StartJoinFund component
import esDashboard from "./locales/Dashboard/dashboard_page/es.json"; // Import the translation file for StartJoinFund component

import enOverview from "./locales/Dashboard/overview_page/en.json"; // Import the translation file for StartJoinFund component
import deOverview from "./locales/Dashboard/overview_page/de.json"; // Import the translation file for StartJoinFund component
import esOverview from "./locales/Dashboard/overview_page/es.json"; // Import the translation file for StartJoinFund component

import enOnboarding from "./locales/onboarding/en.json"; // Import the translation file for StartJoinFund component
import deOnboarding from "./locales/onboarding/de.json"; // Import the translation file for StartJoinFund component
import esOnboarding from "./locales/onboarding/es.json"; // Import the translation file for StartJoinFund component

import enLoadingState from "./locales/Dashboard/LoadingState/en.json"; // Import the translation file for StartJoinFund component
import deLoadingState from "./locales/Dashboard/LoadingState/de.json"; // Import the translation file for StartJoinFund component
import esLoadingState from "./locales/Dashboard/LoadingState/es.json"; // Import the translation file for StartJoinFund component

i18n.use(LanguageDetector).init({
  resources: {
    en: {
      translation: en,
      joinFundHeader: enStartJoinFund.joinFundHeader,
      Pvision: enPvision.Pvision,
      FundAllocation: enFundAllocation.FundAllocation,
      Services: enServices.Services,
      FundReason: enFundReason.FundReason,
      GettingStarted: enGettingStarted.GettingStarted,
      AffiliateRefferal: enAffiliateRefferal.AffiliateRefferal,
      Faq: enFaq.Faq,
      FaqAffiliate: enFaqAffiliate.FaqAffiliate,
      Terms: enTerms.Terms,
      Contact: enContact.Contact,
      Dashboard: enDashboard.Dashboard,
      Overview: enOverview.Overview,
      Onboarding: enOnboarding.Onboarding,
      LoadingStates: enLoadingState.LoadingStates,
    },
    de: {
      translation: de,
      joinFundHeader: deStartJoinFund.joinFundHeader,
      Pvision: dePvision.Pvision,
      FundAllocation: deFundAllocation.FundAllocation,
      Services: deServices.Services,
      FundReason: deFundReason.FundReason,
      GettingStarted: deGettingStarted.GettingStarted,
      AffiliateRefferal: deAffiliateRefferal.AffiliateRefferal,
      Faq: deFaq.Faq,
      FaqAffiliate: deFaqAffiliate.FaqAffiliate,
      Terms: deTerms.Terms,
      Contact: deContact.Contact,
      Dashboard: deDashboard.Dashboard,
      Overview: deOverview.Overview,
      LoadingStates: deLoadingState.LoadingStates,
      Onboarding: deOnboarding.Onboarding,
    },
    es: {
      translation: es,
      joinFundHeader: esStartJoinFund.joinFundHeader,
      Pvision: esPvision.Pvision,
      FundAllocation: esFundAllocation.FundAllocation,
      Services: esServices.Services,
      FundReason: esFundReason.FundReason,
      GettingStarted: esGettingStarted.GettingStarted,
      AffiliateRefferal: esAffiliateRefferal.AffiliateRefferal,
      Faq: esFaq.Faq,
      FaqAffiliate: esFaqAffiliate.FaqAffiliate,
      Terms: esTerms.Terms,
      Contact: esContact.Contact,
      Dashboard: esDashboard.Dashboard,
      Overview: esOverview.Overview,
      LoadingStates: esLoadingState.LoadingStates,
      Onboarding: esOnboarding.Onboarding,
    },
    // Add more languages and translations here as needed
  },
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <React.StrictMode>
      <ThemeProvider>
        <BrowserRouter basename="/">
          <Routes>
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route
              path="/affiliate-dashboard"
              element={<AffiliateDashboard />}
            />

            <Route path="/" element={<Dashboard />} />

            {/* <Route path="/" element={<JoinFund />} /> */}
            <Route path="/affiliates" element={<AffiliateHome />} />
            <Route path="/affiliatesTest" element={<AffiliateIndex />} />
            <Route path="/registration" element={<ReviewRegister />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/affiliates-signup" element={<AffiliateRegister />} />
            <Route path="/admin-page" element={<InternDashboard />} />
            <Route path="/faq" element={<FaqAffiliate />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
    ,
  </I18nextProvider>,
  document.getElementById("root")
);

reportWebVitals();
