import React, { useEffect, useState } from "react";
import "./FormMessages.css";

function FormMessages() {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    fetchFormMessages();
  }, []);

  const fetchFormMessages = () => {
    fetch("https://coinnection-server-node.onrender.com/api/form-messages")
      .then((response) => response.json())
      .then((data) => {
        setMessages(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteMessage = (id) => {
    fetch(
      `https://coinnection-server-node.onrender.com/api/form-messages/${id}`,
      {
        method: "DELETE",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Handle the response from the backend if needed
        fetchFormMessages(); // Fetch updated form messages after deletion
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="form_message_cont">
      <h2>Form Messages</h2>
      {messages.length > 0 ? (
        <ul>
          {messages.map((message) => (
            <div className="form_messages" key={message.id}>
              <li>
                <p>
                  <strong>First Name:</strong> {message.first_name}{" "}
                </p>

                <p>
                  <strong>Last Name:</strong> {message.last_name}
                </p>

                <p>
                  <strong>Email:</strong> {message.email}
                </p>
                <div className="message_form_section">
                  <p>
                    <strong>Message:</strong>{" "}
                  </p>
                  <p>{message.message}</p>
                </div>
                <button onClick={() => deleteMessage(message.id)}>
                  Delete
                </button>
              </li>
            </div>
          ))}
        </ul>
      ) : (
        <p>No messages available.</p>
      )}
    </div>
  );
}

export default FormMessages;
