import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import MonthEndUpdate from "./MonthEndUpdate";
import UnaprovedInvestors from "./UnaprovedInvestors";
import InvestorsList from "./InvestorsList";
import SystemNotifications from "./SystemNotifications";

import en from "../images/us.png";
import de from "../images/de.png";
import es from "../images/es.png";
import FormMessages from "./FormMessages";
import Cookies from "js-cookie";
import { Oval } from "react-loading-icons";
import { BsGlobe } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "../CoinnectionThemes";
import {
  faDesktop,
  faBusinessTime,
  faUsersBetweenLines,
  faUserCheck,
  faPenToSquare,
  faGear,
  faUserPen,
  faInbox,
  faUser,
  faRightFromBracket,
  faLightbulb,
  faMoon,
  faBell,
} from "@fortawesome/free-solid-svg-icons";

function InternDashboard() {
  const [affiliates, setAffiliates] = useState([]);
  const [activeExchange, setActiveExchange] = useState(null);
  const { t, i18n } = useTranslation("Dashboard");
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    Cookies.get("activeTab") || "Home"
  );
  const menuRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownAlertOpen, setDropdownAlertOpen] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [approved, setApproved] = useState(false);

  const [loading, setLoading] = useState(true);
  const { darkMode, toggleDarkMode } = useTheme();
  const [selectedProfilePicture, setSelectedProfilePicture] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [userId, setUserId] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLanguageClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setIsDropdownOpen(false);
    i18n.changeLanguage(language);
  };

  const handleToggleDarkMode = () => {
    toggleDarkMode();
    localStorage.setItem("preferredTheme", darkMode ? "dark" : "white");
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleAlertDropdown = () => {
    setDropdownAlertOpen((prevOpen) => !prevOpen);
  };

  // Function to set the active tab in cookies
  const setActiveTabCookie = (activeTab) => {
    Cookies.set("activeTab", activeTab, {
      expires: 7,
      sameSite: "none",
      secure: true,
    });
  };

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
    setActiveTabCookie(menuItem);
  };
  useEffect(() => {
    const preferredTheme = localStorage.getItem("preferredTheme");
    if (preferredTheme === "dark") {
      toggleDarkMode();
    }

    const activeTabFromCookie = Cookies.get("activeTab");
    if (activeTabFromCookie) {
      setSelectedMenuItem(activeTabFromCookie);
    }
  }, []);

  useEffect(() => {
    document.title = "Admin dashboard"; // Set the tab title
  }, []);

  const handleExchangeClick = (exchange) => {
    setActiveExchange(exchange);
  };

  useEffect(() => {
    // Fetch affiliates data from the backend API
    fetch("https://coinnection-server-node.onrender.com/api/affiliates")
      .then((response) => response.json())
      .then((data) => setAffiliates(data))
      .catch((error) => console.error("Error fetching affiliates:", error));
  }, []);

  return (
    <div
      className={`dashboard_container ${darkMode ? "dark-mode" : "white-mode"}`}
    >
      <div className="dashboard_container_left">
        <div className="side_menu_container" ref={menuRef}>
          <div className="logo_container">
            <p className="dashtittle">Coinnection</p>
            <span className="tlt_beta">Beta</span>
          </div>
          <hr class="line"></hr>
          <ul>
            <li
              className={selectedMenuItem === "Home" ? "active" : ""}
              onClick={() => handleMenuItemClick("Home")}
            >
              <FontAwesomeIcon icon={faDesktop} />
              <span className="menu-text">{t("overview_tab_txt")}</span>
            </li>

            <li
              className={selectedMenuItem === "InvestorsList" ? "active" : ""}
              onClick={() => handleMenuItemClick("InvestorsList")}
            >
              <FontAwesomeIcon icon={faUsersBetweenLines} />
              <span className="menu-text">Investors List</span>
            </li>

            <li
              className={
                selectedMenuItem === "UnapprovedInvestors" ? "active" : ""
              }
              onClick={() => handleMenuItemClick("UnapprovedInvestors")}
            >
              <FontAwesomeIcon icon={faBusinessTime} />
              <span className="menu-text">Unapproved Investors</span>
            </li>
            <li
              className={
                selectedMenuItem === "UnapprovedAffiliates" ? "active" : ""
              }
              onClick={() => handleMenuItemClick("UnapprovedAffiliates")}
            >
              <FontAwesomeIcon icon={faBusinessTime} />
              <span className="menu-text">Unapproved Affiliates</span>
            </li>

            <li
              className={selectedMenuItem === "Refferals" ? "active" : ""}
              onClick={() => handleMenuItemClick("Refferals")}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
              <span className="menu-text">Update End NAV</span>
            </li>

            <li
              className={selectedMenuItem === "Settings" ? "active" : ""}
              onClick={() => handleMenuItemClick("Settings")}
            >
              <FontAwesomeIcon icon={faInbox} />
              <span className="menu-text">Form Inbox</span>
            </li>

            <li
              className={
                selectedMenuItem === "SystemNotifications" ? "active" : ""
              }
              onClick={() => handleMenuItemClick("SystemNotifications")}
            >
              <FontAwesomeIcon icon={faBusinessTime} />
              <span className="menu-text">System Notifications</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="dashboard_container_right">
        <div className="dashboard_container_header">
          <div className="top_menu_dashboard">
            <div className="profile_container">
              <div className="profile_dropdown">
                <button
                  className="theme-toggle-button"
                  onClick={handleToggleDarkMode}
                >
                  <FontAwesomeIcon
                    icon={darkMode ? faLightbulb : faMoon}
                    className="top_menu_icon"
                  />
                </button>
                <button
                  className="profile_button_alert"
                  onClick={toggleAlertDropdown}
                >
                  <FontAwesomeIcon icon={faBell} className="top_menu_icon" />
                </button>

                {dropdownAlertOpen && (
                  <ul
                    className={`alert_dropdown_content ${
                      dropdownAlertOpen ? "open" : ""
                    }`}
                  >
                    <li>
                      <FontAwesomeIcon icon={faBell} />
                      <span className="dropdown_menu_text_alert">
                        notification!
                      </span>
                    </li>
                  </ul>
                )}

                <button className="profile_button" onClick={toggleDropdown}>
                  <FontAwesomeIcon icon={faUser} className="top_menu_icon" />
                </button>

                {dropdownOpen && (
                  <ul
                    className={`profile_dropdown_content ${
                      dropdownOpen ? "open" : ""
                    }`}
                  >
                    <li onClick={() => handleMenuItemClick("Settings")}>
                      <FontAwesomeIcon icon={faGear} />
                      <span className="dropdown_menu_text">Settings</span>
                    </li>
                    <li onClick={() => handleMenuItemClick("Home")}>
                      <FontAwesomeIcon icon={faDesktop} />
                      <span className="dropdown_menu_text">Overview</span>
                    </li>
                  </ul>
                )}

                <div
                  className="fund_menu_links_language"
                  onClick={handleLanguageClick}
                >
                  <div className="language-dropdown">
                    <div className="dropdown-trigger">
                      <span>
                        {" "}
                        <BsGlobe className="BsGlobe_topMenuFund" />
                      </span>
                    </div>
                    {isDropdownOpen && (
                      <div className="dropdown-menu show">
                        <div onClick={() => handleLanguageChange("en")}>
                          <img src={en} alt="English Flag" />
                          <span>{t("English")}</span>
                        </div>
                        <div onClick={() => handleLanguageChange("de")}>
                          <img src={de} alt="German Flag" />
                          <span>{t("Deutsch")}</span>
                        </div>
                        <div onClick={() => handleLanguageChange("es")}>
                          <img src={es} alt="Spanish Flag" />
                          <span>{t("Español")}</span>
                        </div>
                        {/* Add more language options here */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="content-container">
          {selectedMenuItem === "InvestorsList" && <InvestorsList />}
          {selectedMenuItem === "UnaprovedInvestors" && <UnaprovedInvestors />}
          {selectedMenuItem === "Refferals" && <MonthEndUpdate />}
          {selectedMenuItem === "Settings" && <FormMessages />}
          {selectedMenuItem === "SystemNotifications" && (
            <SystemNotifications />
          )}
        </div>
      </div>
    </div>
  );
}

//

export default InternDashboard;
