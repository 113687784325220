import React, { useEffect, useState } from "react";
import axios from "axios";
import "./PerformanceChart.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const formatDate = (date) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(date).toLocaleDateString("en-US", options);
};

const formatXAxisTick = (value) => {
  const date = new Date(value);
  const year = date.getFullYear();
  const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
    date
  );
  const day = date.getDate();
  const dayString = day === 15 || day === 30 ? `${day}th` : day;

  return `${year}, ${month} ${dayString}`;
};

// ... (other imports and code)

const ProfitsBarChart = ({ userId }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        if (userId) {
          const response = await axios.get(
            `https://coinnection-server.onrender.com/api/user-profit-shares/${userId}`
          );

          if (response.data && response.data.length > 0) {
            const transformedData = response.data.map((item) => {
              return {
                formatted_date: formatDate(item.formatted_date),
                "Profit Share": item.profit_share, // Changed the dataKey name here
              };
            });

            setChartData(transformedData);
          } else {
            console.log("No profit share data found.");
          }
        } else {
          console.error("userId is undefined.");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchChartData();
  }, [userId]);

  const formatYAxisTick = (value) => `₮${value}`; // Add $ symbol to Y-axis values

  // Custom Tooltip
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date = new Date(label);
      const formattedDate = `${date.getFullYear()}, ${new Intl.DateTimeFormat(
        "en-US",
        { month: "long" }
      ).format(date)} ${date.getDate()}th`;
      return (
        <div
          className="custom-tooltip"
          style={{
            background: "rgb(28, 27, 29)",
            padding: "5px",
            border: "1px solid #434651",
            borderRadius: "7px",
          }}
        >
          <p>{formattedDate}</p>
          <p>Profit Share: {payload[0].value}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="bar_chart_cont">
      {chartData ? (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            width={500}
            height={300}
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#434651" />
            <XAxis
              dataKey="formatted_date"
              tickFormatter={formatXAxisTick}
              stroke="#434651"
            />
            <YAxis
              domain={[0, 1000]}
              tickFormatter={formatYAxisTick}
              stroke="#434651"
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey="Profit Share" fill="#228b8b" />{" "}
            {/* Updated dataKey name here */}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <div>Loading chart...</div>
      )}
    </div>
  );
};

export default ProfitsBarChart;
