import React, { useState, useEffect } from "react";
import axios from "axios";
import "./UnaprovedInvestors.css";

const UnaprovedInvestors = () => {
  const [affiliates, setAffiliates] = useState([]);

  useEffect(() => {
    // Fetch affiliates data from the backend API
    fetch("https://coinnection-server-node.onrender.com/api/affiliates")
      .then((response) => response.json())
      .then((data) => setAffiliates(data))
      .catch((error) => console.error("Error fetching affiliates:", error));
  }, []);

  return (
    <div className="UnaprovedInvestors_update_cont">
      dddd
      <div className="intern_content_container">
        <h1>review_affiliate List</h1>
        <div className="affiliate-container">
          {affiliates.map((affiliate) => (
            <div key={affiliate.id} className="affiliate-box">
              <div className="affiliate-field">
                <span className="field-label">First Name:</span>
                <span className="field-value">{affiliate.first_name}</span>
              </div>
              <div className="affiliate-field">
                <span className="field-label">Last Name:</span>
                <span className="field-value">{affiliate.last_name}</span>
              </div>
              <div className="affiliate-field">
                <span className="field-label">Email:</span>
                <span className="field-value">{affiliate.email}</span>
              </div>
              <div className="affiliate-field">
                <span className="field-label">Social Media Handle:</span>
                <span className="field-value">
                  {affiliate.social_media_handle}
                </span>
              </div>
              <div className="affiliate-field">
                <span className="field-label">Username:</span>
                <span className="field-value">{affiliate.username}</span>
              </div>
              <div className="affiliate-field">
                <span className="field-label">Wallet Address:</span>
                <span className="field-value">{affiliate.wallet_address}</span>
              </div>
              <div className="affiliate-field">
                <span className="field-label">Affiliate Link:</span>
                <span className="field-value">{affiliate.affiliate_link}</span>
              </div>
              <div className="affiliate-field">
                <span className="field-label">Country of Residence:</span>
                <span className="field-value">
                  {affiliate.country_of_residence}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UnaprovedInvestors;
