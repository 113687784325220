import React from "react";
import "../../Webpage/Webpage_components/JoinFund_components_css/FooterFund1.css";
import LogoFooter from "../../Webpage/Webpage_components/LogoFooter";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { AiFillYoutube } from "react-icons/ai";

import {
  faDiscord,
  faTwitter,
  faTelegram,
  faInstagram,
  faFacebook,
  faYouTube,
} from "@fortawesome/free-brands-svg-icons";

function FooterAffiliate() {
  return (
    <footer className="footer_fund">
      <div className="footer_cont">
        <div className="footer_cont_box1">
          <LogoFooter />
        </div>

        <div className="footer_cont_box2">
          <p>
            The Coinnection Fund is designed to provide access to everyone for
            joining the world of Web3 and investments.
          </p>
          <p>
            Transparency , monthly pay-out, advanced dashboard: The next
            generation of asset management.
          </p>
          <p>Built by real traders, for the for society and beyond.</p>
        </div>

        <div className="footer_cont_box3">
          <p>Be ahead. Stay #coinnected.</p>
        </div>

        <div className="footer_cont_box4">
          <div className="cv_social_box_footer">
            <div className="cv_social_box_icon">
              <FontAwesomeIcon
                icon={faInstagram}
                className="cv_social_box_footer_icon_logo"
              />
            </div>
            <div className="cv_social_box_name">
              {" "}
              <a
                href="https://Instagram.com/coinnection"
                className="cv_link_txt"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </div>
          </div>

          <div className="cv_social_box_footer">
            <div className="cv_social_box_icon">
              <FontAwesomeIcon
                icon={faTwitter}
                className="cv_social_box_footer_icon_logo"
              />
            </div>
            <div className="cv_social_box_name">
              {" "}
              <a
                href="https://Twitter.com/coinnection_"
                className="cv_link_txt"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
            </div>
          </div>

          <div className="cv_social_box_footer">
            <div className="cv_social_box_icon">
              <FontAwesomeIcon
                icon={faTelegram}
                className="cv_social_box_footer_icon_logo"
              />
            </div>
            <div className="cv_social_box_name">
              {" "}
              <a
                href="https://t.me/coinnection"
                className="cv_link_txt"
                target="_blank"
                rel="noopener noreferrer"
              >
                Telegram
              </a>
            </div>
          </div>

          <div className="cv_social_box_footer">
            <div className="cv_social_box_icon">
              <FontAwesomeIcon
                icon={faFacebook}
                className="cv_social_box_footer_icon_logo"
              />
            </div>
            <div className="cv_social_box_name">
              {" "}
              <a
                href="https://Facebook.com/coinnection.net"
                className="cv_link_txt"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </div>
          </div>
        </div>

        <div className="footer_cont_box_contact">
          <div className="footer_cont_box_contact_text"></div>
          <div className="footer_cont_box_contact_text">
            <button className="small_button">
              <a href="coinnection.net/contact">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="faEnvelopeFooter"
                />
                Contact us
              </a>
            </button>
          </div>
        </div>

        <div className="footer_cont_box5">
          <p>©2023 Coinnection - All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
}

export default FooterAffiliate;
