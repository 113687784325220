import React from "react";
import LogoRegisteri from ".//logo2.png";
import { Link } from "react-router-dom";
import "..//Dashboard/user_auth_components/css_user_auth_components/ReviewRegister.css";

function LogoRegister() {
  return (
    <div className="logo_register_form">
      <img src={LogoRegisteri} alt="Coinnection Logo" />
      <Link to="/">The Coinnection Fund</Link>
    </div>
  );
}

export default LogoRegister;
