import React, { useState } from "react";
import "./JoinFund_components_css/Contact.css";

import "../../UniversalStyles/UniversalStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import TopMenuFund from "./TopMenuFund";
function Contact() {
  const { t } = useTranslation("Contact");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create an object with the form data
    const formData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      message: message,
    };

    // Send the form data to your backend API endpoint
    fetch("https://coinnection-server-node.onrender.com/api/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the backend if needed
        console.log(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error(error);
      });

    // Reset the form fields
    setFirstName("");
    setLastName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div className="contact-section" id="contact">
      <TopMenuFund />
      <p className="contact_us_title">{t("title")}</p>
      <div className="fund_contact_content">
        <div className="contact-info">
          <p className="contact_us_utitle">{t("content")}</p>
        </div>
        <div className="form_cont">
          <div className="email_form">
            <form onSubmit={handleSubmit}>
              <div className="name-input">
                <div className="input-field">
                  <label htmlFor="first-name">{t("firstName")}</label>
                  <br />
                  <input
                    type="text"
                    id="first-name"
                    name="first-name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="last-name">{t("lastName")}</label>
                  <br />
                  <input
                    type="text"
                    id="last-name"
                    name="last-name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="email">{t("email")}</label>
                <br />
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input-field">
                <label htmlFor="message">{t("message")}</label>
                <br />
                <textarea
                  type="message"
                  id="message"
                  name="message"
                  value={message}
                  placeholder="Hi! I have a few questions regarding...."
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="contact_submit_btn_container">
                <input type="submit" value={t("submitButton")} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
