import React from "react";
import QRCode from "react-qr-code"; // Make sure this import is correct
import LogoModal from "./LogoModal";

const QRCodeModal = ({ referralLink, onClose }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <LogoModal />
        <p>Register Now. Earn Crypto Together.</p>
        <QRCode value={referralLink} />
      </div>
    </div>
  );
};

export default QRCodeModal;
