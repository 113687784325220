import React, { useState, useEffect } from "react";
import axios from "axios";
import "./InvestorsList.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

const InvestorsList = () => {
  const [investors, setInvestors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSorted, setIsSorted] = useState(false);
  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [investorToDelete, setInvestorToDelete] = useState(null);

  useEffect(() => {
    axios
      .get("https://coinnection-server.onrender.com/api/investors")
      .then((response) => {
        setInvestors(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const approveInvestor = (userId) => {
    axios
      .post("https://coinnection-server.onrender.com/api/approve-account", {
        userId: userId,
      })
      .then((response) => {
        console.log(response.data.message);
        // Update the local state or perform any other necessary actions
      })
      .catch((error) => {
        console.error("Failed to approve account:", error);
      });
  };

  const deleteInvestor = (userId) => {
    setShowDeleteConfirmation(false); // Close the confirmation pop-up
    axios
      .delete("https://coinnection-server.onrender.com/api/delete-investor", {
        data: { userId: userId },
      })
      .then((response) => {
        console.log(response.data.message);
        // Update the local state or perform any other necessary actions
      })
      .catch((error) => {
        console.error("Failed to delete investor:", error);
      });
  };

  const confirmDelete = (userId) => {
    setInvestorToDelete(userId);
    setShowDeleteConfirmation(true);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleSort = () => {
    setIsSorted(!isSorted);
  };

  const filteredAndSortedInvestors = investors
    .filter(
      (investor) =>
        investor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        investor.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        investor.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        investor.phone_number
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        investor.crypto_address.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (isSorted) {
        return a.name.localeCompare(b.name);
      }
      return 0;
    });

  const approvedInvestors = filteredAndSortedInvestors.filter(
    (investor) => investor.approved
  );
  const unapprovedInvestors = filteredAndSortedInvestors.filter(
    (investor) => !investor.approved
  );

  const copyToClipboard = (text) => {
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = text;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextArea);

    // Show the copy popup
    setShowCopyPopup(true);

    // Hide the copy popup after 2 seconds
    setTimeout(() => {
      setShowCopyPopup(false);
    }, 2000);
  };

  return (
    <div className="month_end_update_cont">
      <div className="investors_cont_box">
        <div className="bar_div">
          <div className="search_bar_div">
            <input
              type="text"
              placeholder="Search by investor information..."
              onChange={handleSearch}
            />
          </div>

          <div className="actions_bar_div">
            <button onClick={toggleSort} className="small_button">
              Sort by Name
            </button>
          </div>
        </div>

        {/* Unapproved Investors List */}
        <div className="UnapprovedInvestorsList">
          <h3>Unapproved Investors:</h3>
          {unapprovedInvestors.map((investor, index) => (
            <div key={index} className="InvestorBoxUnapproved">
              <div className="InvestorBox_header">
                <div className="InvestorBox_header_cont">
                  <p className="InvestorBox_name">
                    {investor.name} {investor.last_name}
                  </p>
                  <p className="InvestorBox_username">{investor.username}</p>
                </div>
                <div className="InvestorBox_header_cont_right">
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    className="InvestorBox_header_cont_right_icon"
                    onClick={() => confirmDelete(investor.id)}
                  />
                </div>
              </div>
              <div className="InvestorBox_body">
                <div className="InvestorBox_body_section_1">
                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong onClick={() => copyToClipboard(investor.email)}>
                        Email:
                      </strong>{" "}
                      <span onClick={() => copyToClipboard(investor.email)}>
                        {" "}
                        {investor.email}
                      </span>
                    </p>
                  </div>
                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong
                        onClick={() => copyToClipboard(investor.phone_number)}
                      >
                        Phone Number:
                      </strong>{" "}
                      <span
                        onClick={() => copyToClipboard(investor.phone_number)}
                      >
                        {investor.phone_number}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="InvestorBox_body_section_1">
                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong
                        onClick={() =>
                          copyToClipboard(investor.investment_amount)
                        }
                      >
                        Investment Amount:
                      </strong>{" "}
                      <span
                        onClick={() =>
                          copyToClipboard(investor.investment_amount)
                        }
                      >
                        {investor.investment_amount}
                      </span>
                    </p>
                  </div>
                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong
                        onClick={() => copyToClipboard(investor.profit_split)}
                      >
                        Profit Split:
                      </strong>{" "}
                      <span
                        onClick={() => copyToClipboard(investor.profit_split)}
                      >
                        {investor.profit_split}%
                      </span>
                    </p>
                  </div>
                </div>

                <div className="InvestorBox_body_section_1">
                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong
                        onClick={() => copyToClipboard(investor.crypto_address)}
                      >
                        Crypto Address:
                      </strong>{" "}
                    </p>
                    <input
                      onClick={() => copyToClipboard(investor.crypto_address)}
                      type="text"
                      value={investor.crypto_address}
                      readOnly
                    />
                  </div>
                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong
                        onClick={() => copyToClipboard(investor.network_chain)}
                      >
                        Network Chain:
                      </strong>{" "}
                      <span
                        onClick={() => copyToClipboard(investor.network_chain)}
                      >
                        {investor.network_chain}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="InvestorBox_body_section_1">
                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong>Approved? :</strong>{" "}
                      <span>{investor.approved ? "Yes" : "No"}</span>
                    </p>
                  </div>
                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong>Investment Lockup:</strong>{" "}
                      <span>{investor.investment_lockup}</span>
                    </p>
                  </div>
                </div>

                <div className="InvestorBox_body_section_1">
                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong
                        onClick={() =>
                          copyToClipboard(investor.country_residence)
                        }
                      >
                        Country Residence:
                      </strong>{" "}
                      <span
                        onClick={() =>
                          copyToClipboard(investor.country_residence)
                        }
                      >
                        {investor.country_residence}
                      </span>
                    </p>
                  </div>

                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong onClick={() => copyToClipboard(investor.id)}>
                        User ID:
                      </strong>{" "}
                      <span onClick={() => copyToClipboard(investor.id)}>
                        {investor.id}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="UnapprovedInvestorsList_footer">
                <button
                  className="UnapprovedInvestorsList_footer_button"
                  onClick={() => approveInvestor(investor.id)}
                >
                  Approve
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Approved Investors List */}
        <div className="ApprovedInvestorsList">
          <h3>Approved Investors:</h3>
          {approvedInvestors.map((investor, index) => (
            <div key={index} className="InvestorBox">
              <div className="InvestorBox_header">
                <p className="InvestorBox_name">
                  {investor.name} {investor.last_name}
                </p>
                <p className="InvestorBox_username">{investor.username}</p>
              </div>

              <div className="InvestorBox_body">
                <div className="InvestorBox_body_section_1">
                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong onClick={() => copyToClipboard(investor.email)}>
                        Email:
                      </strong>{" "}
                      <span>{investor.email}</span>
                    </p>
                  </div>
                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong
                        onClick={() => copyToClipboard(investor.phone_number)}
                      >
                        Phone Number:
                      </strong>{" "}
                      <span
                        onClick={() => copyToClipboard(investor.phone_number)}
                      >
                        {investor.phone_number}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="InvestorBox_body_section_1">
                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong
                        onClick={() =>
                          copyToClipboard(investor.investment_amount)
                        }
                      >
                        Investment Amount:
                      </strong>{" "}
                      <span
                        onClick={() =>
                          copyToClipboard(investor.investment_amount)
                        }
                      >
                        {investor.investment_amount}
                      </span>
                    </p>
                  </div>
                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong
                        onClick={() => copyToClipboard(investor.profit_split)}
                      >
                        Profit Split:
                      </strong>{" "}
                      <span
                        onClick={() => copyToClipboard(investor.profit_split)}
                      >
                        {investor.profit_split}%
                      </span>
                    </p>
                  </div>
                </div>

                <div className="InvestorBox_body_section_1">
                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong
                        onClick={() => copyToClipboard(investor.crypto_address)}
                      >
                        Crypto Address:
                      </strong>{" "}
                    </p>
                    <input
                      onClick={() => copyToClipboard(investor.crypto_address)}
                      type="text"
                      value={investor.crypto_address}
                      readOnly
                    />
                  </div>
                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong
                        onClick={() => copyToClipboard(investor.network_chain)}
                      >
                        Network Chain:
                      </strong>{" "}
                      <span
                        onClick={() => copyToClipboard(investor.network_chain)}
                      >
                        {investor.network_chain}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="InvestorBox_body_section_1">
                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong>Approved? :</strong>{" "}
                      <span>{investor.approved ? "Yes" : "No"}</span>
                    </p>
                  </div>
                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong>Investment Lockup:</strong>{" "}
                      <span>{investor.investment_lockup}</span>
                    </p>
                  </div>
                </div>

                <div className="InvestorBox_body_section_1">
                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong
                        onClick={() =>
                          copyToClipboard(investor.country_residence)
                        }
                      >
                        Country Residence:
                      </strong>{" "}
                      <span
                        onClick={() =>
                          copyToClipboard(investor.country_residence)
                        }
                      >
                        {investor.country_residence}
                      </span>
                    </p>
                  </div>

                  <div className="InvestorBox_body_section_1_txt">
                    <p>
                      <strong onClick={() => copyToClipboard(investor.id)}>
                        User ID:
                      </strong>{" "}
                      <span onClick={() => copyToClipboard(investor.id)}>
                        {investor.id}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {showDeleteConfirmation && (
        <div className="deleteConfirmation">
          <div className="deleteConfirmation_text">
            <p>Are you sure you want to delete this investor?</p>
          </div>
          <div className="deleteConfirmation_buttons">
            <button
              className="UnapprovedInvestorsList_footer_button"
              onClick={() => deleteInvestor(investorToDelete)}
            >
              Yes, Delete
            </button>
            <button
              className="UnapprovedInvestorsList_footer_button"
              onClick={() => setShowDeleteConfirmation(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {showCopyPopup && <div className="copyPopup">Text Copied!</div>}
    </div>
  );
};

export default InvestorsList;
