import React, { useState, useEffect } from "react";
import axios from "axios";

import "./css_Settings_page/RedemptionForm.css";
import { useTheme } from "../../../CoinnectionThemes";
import Cookies from "js-cookie";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";
axios.defaults.withCredentials = true;
const RedemptionForm = () => {
  const [sendRedemption, setSendRedemption] = useState("");
  const [feedback, setFeedback] = useState("");
  const [message, setMessage] = useState(null);
  const [isRedemptionRequested, setIsRedemptionRequested] = useState(false); // Updated initialization to false
  const [userEmail, setUserEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // New state variable for initial loading
  const { darkMode, toggleDarkMode } = useTheme();
  const [isRedemptionStatusFetched, setIsRedemptionStatusFetched] =
    useState(false);
  const [redemptionDate, setRedemptionDate] = useState(null);

  // Fetch the redemption status and user email when the component mounts
  useEffect(() => {
    fetchRedemptionStatus();
  }, []); // Empty dependency array to run only once

  useEffect(() => {
    // Check if the redemption status is stored in a cookie
    const storedRedemptionStatus = Cookies.get("isRedemptionRequested");
    setIsRedemptionRequested(storedRedemptionStatus === "true");
    setIsRedemptionStatusFetched(true); // Mark that the redemption status has been fetched

    // Check if the user email is stored in a cookie
    const storedUserEmail = Cookies.get("userEmail");
    if (isRedemptionRequested) {
      setUserEmail(storedUserEmail);
    }
    setIsLoading(false); // Mark the initial loading as complete
  }, [isRedemptionRequested]);

  const fetchRedemptionStatus = () => {
    setIsLoading(true);
    axios
      .get(
        "https://coinnection-server.onrender.com/api/get-redemption-status",
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("authToken")}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setMessage(null);
        setIsRedemptionRequested(response.data.isRedemptionRequested);
        if (response.data.isRedemptionRequested) {
          setMessage(
            `Redemption already requested by ${response.data.userEmail}`
          );

          // Remove the "new Date()" call and directly use the redemptionDate value
          console.log(
            "Redemption date from server:",
            response.data.redemptionDate
          );

          setRedemptionDate(response.data.redemptionDate);

          setUserEmail(response.data.userEmail); // Update userEmail state
          console.log("Server response:", response.data); // Add this console log
        }
      })
      .catch((error) => {
        console.error("Failed to fetch redemption status:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const SendRedemption = () => {
    // Show a confirmation pop-up before sending the redemption request
    const confirmed = window.confirm(
      "Are you sure you want to request redemption?"
    );

    if (confirmed) {
      axios
        .get("https://coinnection-server.onrender.com/api/user-info", {
          headers: {
            Authorization: `Bearer ${Cookies.get("authToken")}`,
          },
          withCredentials: true,
        })
        .then((response) => {
          const userEmail = response.data.email;
          const redemptionData = {
            email: userEmail,
            dateOfRedemption: new Date().toISOString(),
            reason: sendRedemption,
            feedback: feedback,
          };
          axios
            .post(
              "https://coinnection-server.onrender.com/api/redemption",
              redemptionData,
              {
                headers: {
                  Authorization: `Bearer ${Cookies.get("authToken")}`,
                },
                withCredentials: true,
              }
            )
            .then((response) => {
              setMessage("Redemption successfully requested");
              setIsRedemptionRequested(true);
              setUserEmail(userEmail);
              // Store the redemption status and user email in local storage
              // Store the redemption status and user email in cookies
              Cookies.set("isRedemptionRequested", true, { expires: 90 }); // Set the cookie to expire in 7 days
              Cookies.set("userEmail", userEmail, { expires: 90 }); // Set the cookie to expire in 7 days
            })
            .catch((error) => {
              console.error("Failed to send redemption:", error);
              setMessage("Failed to send redemption");
            });
        })
        .catch((error) => {
          console.error("Failed to fetch user info:", error);
        });
    }
  };

  const CancelRedemption = () => {
    const isRequested = Cookies.get("isRedemptionRequested") === "true";

    if (isRequested) {
      axios
        .post(
          "https://coinnection-server.onrender.com/api/cancel-redemption",
          { email: userEmail },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          console.log("Redemption cancellation response:", response.data);
          setMessage("Redemption successfully cancelled");
          setIsRedemptionRequested(false);
          Cookies.remove("isRedemptionRequested");
          // Do not set the userEmail to null here
        })
        .catch((error) => {
          console.error("Failed to cancel redemption:", error);
          setMessage("Failed to cancel redemption");
        });
    } else {
      alert("There is no redemption to cancel.");
    }
  };

  return (
    <div className={`redemption_cont ${darkMode ? "dark-mode" : "white-mode"}`}>
      <div className="settings_cont_section_div_body_cont_name">
        <p className="settings_cont_section_div_body_cont_name_text_title">
          Repayment
        </p>
      </div>
      {message && <p>{message}</p>}
      <textarea
        placeholder="Reason for redemption"
        value={sendRedemption}
        onChange={(e) => setSendRedemption(e.target.value)}
      />
      <textarea
        placeholder="Any feedback?"
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
      />

      {isLoading || !isRedemptionStatusFetched ? (
        <p>Loading...</p>
      ) : (
        <div className="redemption_cont_button_box">
          {isRedemptionRequested && (
            <div className="redemption_cont_button_box_request_txt">
              <div className="redemption_cont_button_box_request_txt_box">
                <FontAwesomeIcon
                  icon={faHandHoldingDollar}
                  className="redemption_icon"
                />
              </div>

              <div className="redemption_cont_button_box_request_txt_box">
                {userEmail && (
                  <p>
                    Redemption requested by {userEmail}
                    {redemptionDate && (
                      <span>
                        {" "}
                        on {new Date(redemptionDate).toLocaleDateString()}
                      </span>
                    )}
                  </p>
                )}
              </div>
              <div className="redemption_cont_button_box_request_txt_box">
                <button
                  onClick={CancelRedemption}
                  disabled={!isRedemptionRequested}
                >
                  Cancel Redemption
                </button>
              </div>
            </div>
          )}

          <div className="redemption_cont_buttons">
            <button onClick={SendRedemption} disabled={isRedemptionRequested}>
              Repayment
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RedemptionForm;
