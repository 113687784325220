import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./StartPageAffiliate.css";

import logo3 from "../../images/logo3.png";

const StartPageAffiliate = () => {
  return (
    <div className="StartPageAffiliate_page_section">
      <div className="StartPageAffiliate_body">
        <div className="StartPageAffiliate_tittle">
          <div className="StartPageAffiliate_tlt_1_box">
            <p className="StartPageAffiliate_tlt_1">
              Leverage your network and start earning with Coinnection Fund
            </p>
          </div>
          <p className="StartPageAffiliate_tlt_2">
            The Coinnection Fund Affiliate program shares 10% of all generated
            profits out of our own pocket, every time our clients get paid, you
            do too - simple as that.
          </p>

          <div className="StartPageAffiliate_tlt_3_box">
            <p className="StartPageAffiliate_tlt_2">
              Receive 10% Profit Split from all your affiliates earnings - each
              month, for life.
            </p>
          </div>

          <div className="btn_div">
            <button className="affiliate_body_btn">
              <Link to="/affiliate-signup">Start your application</Link>
            </button>
          </div>
        </div>
        <div className="affiliate_logo_right">
          {" "}
          <img src={logo3} alt="Coinnection Logo" />
        </div>
      </div>
    </div>
  );
};

export default StartPageAffiliate;
