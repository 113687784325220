import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Logo from "./Logo";
import "./JoinFund_components_css/TopMenuFund.css";
import en from "../../images/us.png";
import de from "../../images/de.png";
import es from "../../images/es.png";
import { BsGlobe } from "react-icons/bs";

function TopMenuFund() {
  const { t, i18n } = useTranslation();
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const handleLanguageClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setIsDropdownOpen(false);
    i18n.changeLanguage(language);
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLinkClick = (event) => {
    event.preventDefault();
    const target = event.target.getAttribute("href");
    const section = document.querySelector(target);
    section.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    setMenuVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuVisible && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [menuVisible]);

  useEffect(() => {
    // Hide the menu on initial load
    setMenuVisible(false);
  }, []);

  return (
    <div
      className={`fund_menu_container ${menuVisible ? "active" : ""}`}
      ref={menuRef}
    >
      <div className="fund_menu">
        <div className="fund_menu_logo_cont">
          <Logo />
        </div>
        <div
          className={`fund_menu_icon ${menuVisible ? "active" : ""}`}
          onClick={toggleMenu}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div className={`fund_menu_links ${menuVisible ? "active" : ""}`}>
          <a href="#vision" onClick={handleLinkClick}>
            {t("menu.ourVision")}
          </a>

          <a href="#investment" onClick={handleLinkClick}>
            {t("menu.investmentPackages")}
          </a>
          <a href="#getting-started" onClick={handleLinkClick}>
            {t("menu.getStarted")}
          </a>
          <a href="#why-choose-us" onClick={handleLinkClick}>
            {t("menu.ourBenefits")}
          </a>

          <a href="#affiliate-refferal" onClick={handleLinkClick}>
            {t("menu.affiliates")}
          </a>

          <div className="fund_menu_links_btns">
            <button
              className="login_fund_btn"
              onClick={() => {
                window.location.href = "https://coinnection.net/dashboard";
              }}
            >
              {t("menu.memberLogin")}
            </button>
            <button
              className="join-fund-btn"
              onClick={() => {
                window.location.href = "https://coinnection.net/registration";
              }}
            >
              {t("menu.registration")}
            </button>
            <div
              className="fund_menu_links_language"
              onClick={handleLanguageClick}
            >
              <div className="fund__top_menu_language_dropdown">
                <div className="fund__top_menu_dropdown_trigger">
                  <span>
                    {" "}
                    <BsGlobe className="BsGlobe_topMenuFund" />
                  </span>
                </div>
                {isDropdownOpen && (
                  <div className="top_menu_dropdown_menu show">
                    <div onClick={() => handleLanguageChange("en")}>
                      <img src={en} alt="English Flag" />
                      <span>{t("English")}</span>
                    </div>
                    <div onClick={() => handleLanguageChange("de")}>
                      <img src={de} alt="German Flag" />
                      <span>{t("Deutsch")}</span>
                    </div>
                    <div onClick={() => handleLanguageChange("es")}>
                      <img src={es} alt="Spanish Flag" />
                      <span>{t("Español")}</span>
                    </div>
                    {/* Add more language options here */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopMenuFund;
