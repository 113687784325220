import React, { useEffect, useState } from "react";
import axios from "axios";
import "./css_Settings_page/Settings.css";
import { useTranslation } from "react-i18next";
import PasswordChangeForm from "./PasswordChangeForm";
import RedemptionForm from "./RedemptionForm";
import { useTheme } from "../../../CoinnectionThemes"; // Adjust the path as needed

import Cookies from "js-cookie"; // Import Cookies library to access cookies
import matic_chain from "../../../images/matic_chain.png"; // Path to Matic logo
import bsc_chain from "../../../images/bsc_chain.png"; // Path to BSC logo
import arb_chain from "../../../images/arb_chain.svg"; // Path to Arbitrum logo

import en from "../../../images/us.png";
import de from "../../../images/de.png";
import es from "../../../images/es.png";

// Define language options
const languageOptions = [
  { code: "en", name: "English", flag: en },
  { code: "de", name: "Deutsch", flag: de },
  { code: "es", name: "Español", flag: es },
  // Add more languages here
];

const Settings = () => {
  const [userInfo, setUserInfo] = useState(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t, i18n } = useTranslation("Dashboard");
  const { darkMode, toggleDarkMode } = useTheme();

  // Initialize the selectedLanguage state with the language from the cookie or a default value
  const [selectedLanguage, setSelectedLanguage] = useState(
    Cookies.get("language") || "en"
  );

  // Function to get the logo based on the chain name
  const getChainLogo = (chainName) => {
    switch (chainName) {
      case "matic":
        return matic_chain;
      case "bsc":
        return bsc_chain;
      case "arb":
        return arb_chain;
      default:
        return null; // Or some default image if needed
    }
  };

  // Function to get the name of the current language
  const getCurrentLanguageName = () => {
    const currentLanguage = languageOptions.find(
      (lang) => lang.code === selectedLanguage
    );
    return currentLanguage ? currentLanguage.name : "Language";
  };

  useEffect(() => {
    // Update i18n language based on the language in the cookie
    if (Cookies.get("language")) {
      i18n.changeLanguage(Cookies.get("language"));
    }

    // Get the authToken from cookies
    const authToken = Cookies.get("authToken");

    // Fetch the user information using the API endpoint
    axios
      .get("https://coinnection-server.onrender.com/api/user-info", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        setUserInfo(response.data);
      })
      .catch((error) => {
        console.error("Failed to fetch user information:", error);
      });
  }, [i18n]);

  const handleLanguageClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setIsDropdownOpen(false);
    i18n.changeLanguage(language);
  };

  return (
    <div className={`settings_cont ${darkMode ? "dark-mode" : "white-mode"}`}>
      <div className="settings_cont_section_div">
        <div className="settings_cont_section_div_header">
          <p className="settings_cont_section_div_header_tittle">Account</p>

          {/* <p className="settings_cont_section_div_header_uid">UID:</p> */}
        </div>

        <div className="settings_cont_section_div_body">
          {userInfo && (
            <>
              <div className="settings_cont_section_div_body_cont">
                <div className="settings_cont_section_div_body_cont_name">
                  <p className="settings_cont_section_div_body_cont_name_text">
                    Name:
                  </p>
                  <input type="text" value={userInfo.name} readOnly />
                </div>

                <div className="settings_cont_section_div_body_cont_name">
                  <p className="settings_cont_section_div_body_cont_name_text">
                    Last Name:
                  </p>
                  <input type="text" value={userInfo.last_name} readOnly />
                </div>
              </div>

              <div className="settings_cont_section_div_body_cont">
                <div className="settings_cont_section_div_body_cont_name">
                  <p className="settings_cont_section_div_body_cont_name_text">
                    Email:
                  </p>
                  <input type="text" value={userInfo.email} readOnly />
                </div>

                <div className="settings_cont_section_div_body_cont_name">
                  <p className="settings_cont_section_div_body_cont_name_text">
                    Phone number:
                  </p>
                  <input type="text" value={userInfo.phone_number} readOnly />
                </div>
              </div>

              <div className="settings_cont_section_div_body_cont">
                <div className="settings_cont_section_div_body_cont_name">
                  <p className="settings_cont_section_div_body_cont_name_text">
                    Network (Chain):
                  </p>
                  <div className="input-logo-container">
                    <img
                      src={getChainLogo(userInfo.network_chain)}
                      alt="Chain Logo"
                      className="chain-logo"
                    />
                    <input
                      type="text"
                      value={userInfo.network_chain}
                      readOnly
                      className="input-with-logo"
                    />
                  </div>
                </div>

                <div className="settings_cont_section_div_body_cont_name">
                  <p className="settings_cont_section_div_body_cont_name_text">
                    Address:
                  </p>
                  <input type="text" value={userInfo.crypto_address} readOnly />
                </div>
              </div>

              <div className="settings_cont_section_div_body_cont">
                <div className="settings_cont_section_div_body_cont_name">
                  <p className="settings_cont_section_div_body_cont_name_text">
                    Residence:
                  </p>
                  <input
                    type="text"
                    value={userInfo.country_residence}
                    readOnly
                  />
                </div>

                <div className="settings_cont_section_div_body_cont_name">
                  <p className="settings_cont_section_div_body_cont_name_text">
                    Investment Lock-up:
                  </p>
                  <input
                    type="text"
                    value={userInfo.investment_lockup}
                    readOnly
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <div className="settings_cont_section_div_header_security">
          <p className="settings_cont_section_div_header_tittle">
            General Settings
          </p>
        </div>

        <div className="settings_cont_section_div_body_cont">
          {" "}
          <div className="settings_cont_section_div_body_cont_name">
            <p className="settings_cont_section_div_body_cont_name_text_title">
              Language
            </p>
            <div
              className="fund_menu_links_language"
              onClick={handleLanguageClick}
            >
              <div className="language-dropdown">
                <div className="dropdown-trigger">
                  <span>
                    {/* <BsGlobe className="BsGlobe_topMenuFund" /> */}
                    {getCurrentLanguageName()}
                  </span>
                </div>
                {isDropdownOpen && (
                  <div className="dropdown-menu show">
                    {languageOptions.map((lang) => (
                      <div
                        key={lang.code}
                        onClick={() => handleLanguageChange(lang.code)}
                      >
                        <img src={lang.flag} alt={`${lang.name} Flag`} />
                        <span>{t(lang.name)}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="settings_cont_section_div_body_cont">
          {" "}
          <div className="settings_cont_section_div_body_cont_name">
            <p className="settings_cont_section_div_body_cont_name_text_title">
              Notifications(In works)
            </p>
          </div>
        </div>

        <div className="toggle-switch">
          <div className="settings_cont_section_div_body_cont_name">
            <p className="settings_cont_section_div_body_cont_name_text">
              Profits Update E-mail Notifications
            </p>
          </div>
          <input type="checkbox" id="emailToggle" className="checkbox" />
          <label className="label" htmlFor="emailToggle">
            <span className="inner"></span>
            <span className="switch"></span>
          </label>
        </div>

        <div className="toggle-switch">
          <div className="settings_cont_section_div_body_cont_name">
            <p className="settings_cont_section_div_body_cont_name_text">
              System Updates E-mail Notifications
            </p>
          </div>
          <input type="checkbox" id="emailToggle" className="checkbox" />
          <label className="label" htmlFor="emailToggle">
            <span className="inner"></span>
            <span className="switch"></span>
          </label>
        </div>

        <div className="toggle-switch">
          <div className="settings_cont_section_div_body_cont_name">
            <p className="settings_cont_section_div_body_cont_name_text">
              Newsletter
            </p>
          </div>
          <input type="checkbox" id="mewsletterToggle" className="checkbox" />
          <label className="label" htmlFor="mewsletterToggle">
            <span className="inner"></span>
            <span className="switch"></span>
          </label>
        </div>

        <div className="settings_cont_section_div_body_cont_name">
          <p className="settings_cont_section_div_body_cont_name_text_title">
            Color Theme
          </p>
        </div>

        <div className="toggle-switch">
          <div className="settings_cont_section_div_body_cont_name">
            <p className="settings_cont_section_div_body_cont_name_text">
              Enable Dark Mode
            </p>
          </div>
          <input
            type="checkbox"
            id="darkModeToggle"
            className="checkbox"
            checked={darkMode}
            onChange={toggleDarkMode}
          />
          <label className="label" htmlFor="darkModeToggle">
            <span className="inner"></span>
            <span className="switch"></span>
          </label>
        </div>

        <div className="settings_cont_section_div_header_security">
          <p className="settings_cont_section_div_header_tittle">Security</p>
        </div>

        <div className="settings_cont_section_div_body_cont">
          <PasswordChangeForm />
        </div>

        <div className="settings_cont_section_div_header_security">
          <p className="settings_cont_section_div_header_tittle">Repayment</p>
        </div>

        <div className="settings_cont_section_div_body_cont">
          <RedemptionForm />
        </div>
      </div>
    </div>
  );
};

export default Settings;
