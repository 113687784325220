import React from "react";
import { Oval } from "react-loading-icons";

const LoadingStateSpinner = () => {
  return (
    <div className="loading_2">
      <Oval className="approval_header_icon" />
    </div>
  );
};

export default LoadingStateSpinner;
