import React, { useState, useEffect } from "react";
import axios from "axios";
import LogoRegister from "../../images/LogoRegister";
import "./dashboard_components_css/ForgotPassword.css";
import { useTheme } from "../../CoinnectionThemes";
import Cookies from "js-cookie"; // Import the Cookies library

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { darkMode } = useTheme();
  const [resetToken, setResetToken] = useState("");
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [authToken, setAuthToken] = useState(Cookies.get("authToken")); // Initialize authToken state from cookie

  const handleResetRequest = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://coinnection-server.onrender.com/api/reset-password-request",
        { email },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the authToken in the headers
          },
        }
      );

      // Extract the reset token from the response headers
      const resetTokenFromHeader = response.headers["x-reset-token"];
      if (resetTokenFromHeader) {
        setResetToken(resetTokenFromHeader);
      } else {
        console.error("Reset token not found in response header.");
      }

      setMessage(response.data.message);
      setIsMessageVisible(true); // Show the message
    } catch (error) {
      console.error("Password reset request error:", error);
      setMessage("Error requesting password reset.");
      setIsMessageVisible(true); // Show the message
    }

    setTimeout(() => {
      setIsMessageVisible(false); // Hide the message after 3 seconds
    }, 3000);
  };

  // useEffect to update the authToken state from cookies when needed
  useEffect(() => {
    setAuthToken(Cookies.get("authToken"));
  }, []);

  return (
    <div
      className={`forgot_pass_request_section ${
        darkMode ? "dark-mode" : "white-mode"
      }`}
    >
      <div className="forgot_pass_request_box">
        <form onSubmit={handleResetRequest}>
          <div className="forgot_pass_request_header">
            <LogoRegister />
          </div>

          <div className="forgot_pass_request_header_welcome">
            <p className="forgot_pass_welcome_tlt_txt_1">
              Request to reset your password 🔒
            </p>
            <p className="forgot_pass_welcome_txt_2">
              Please provide your email associated with the Coinnection Fund
              below:
            </p>
          </div>

          <div className="forgot_pass_subcont1">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>

          <div className="forgot_pass_subcont1_buttons">
            <div className="submit_icon_txt">
              <button type="submit">Reset password</button>
            </div>
          </div>
        </form>
        {isMessageVisible && (
          <div className="error_box">
            <p
              className={
                message.includes("Error") ? "error-message" : "success-message"
              }
            >
              {message}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
