import React from "react";
import StartJoinFund from "./Webpage_components/StartJoinFund";
import Pvision from "./Webpage_components/Pvision";
import FundAllocation from "./Webpage_components/FundAllocation";
import Services from "./Webpage_components/Services";
import FundReason from "./Webpage_components/FundReason";
import GettingStarted from "./Webpage_components/GettingStarted";
import AffiliateRefferal from "./Webpage_components/AffiliateRefferal";
import Faq from "./Webpage_components/Faq";
import FooterFund1 from "./Webpage_components/FooterFund1";
import TopMenuFund from "./Webpage_components/TopMenuFund";
import "./JoinFund.css";

function JoinFund() {
  return (
    <div className="fund_page">
      <TopMenuFund />
      <section className="scroll-section" id="home">
        <StartJoinFund />
      </section>

      {/* ---------Purpose and vision--------- */}
      <section className="scroll-section-2" id="vision">
        <Pvision />
      </section>

      {/* ---------Investment packages--------- */}
      <section className="scroll-section-4" id="investment">
        <Services />
      </section>

      {/* ---------Getting Started--------- */}
      <section className="scroll-section-6" id="getting-started">
        <GettingStarted />
      </section>

      {/* ---------Reasons to join the Coinnection Fund--------- */}
      <section className="scroll-section-5" id="why-choose-us">
        <FundReason />
      </section>

      {/* ---------Coinnection Fund Allocation--------- */}
      <section className="scroll-section-3" id="allocation">
        <FundAllocation />
      </section>

      {/* ---------Affiliate and Refferal--------- */}
      <section className="scroll-section-7" id="affiliate-refferal">
        <AffiliateRefferal />
      </section>

      {/* ---------FAQ--------- */}
      <section className="scroll-section" id="faq">
        <Faq />
      </section>

      {/* ---------Contact Us--------- */}
      {/* <section className="scroll-section-10" id="contact">
        <Contact />
      </section> */}

      <FooterFund1 />
    </div>
  );
}

export default JoinFund;
